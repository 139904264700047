import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import ArrowSteps from "../components/ArrowSteps";
import LandingIntro from "../components/LandingIntro";
import SingleOptions from "../components/SingleOptions";
import StackedList from "../components/StackedList";
import ContactForm from "../components/ContactForm";
import Spinner from "../images/spinner.png";
import { _onboarding_options } from "../modules/_onboarding_options";
import Fade from "react-reveal/Fade";
import { _auth } from "../modules/_auth";
import Skeleton from "../components/Skeleton";
import Input from "../components/Input";
import { _user } from "../modules/_user";
import InputList from "../components/InputList";
import ListBoxSelector from "../components/ListBoxSelector";
import MultiSelectListBox from "../components/MultiSelectListBox1";
import Button from "../elements/Button";
import { _teams } from "../modules/_teams";
const steps = _onboarding_options.getOnboardingSteps();
const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();
const options = _onboarding_options.getAllSettingsOptions();

const mailingLists = [
  {
    id: 1,
    name: "Food & Beverage",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 2,
    name: "Hospitality",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 3,
    name: "Retail",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 4,
    name: "Education",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 5,
    name: "Healthcare",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 6,
    name: "Financial",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 7,
    name: "Consumer Services",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 8,
    name: "Manufacturing",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 9,
    name: "Government",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 10,
    name: "Transportation",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 11,
    name: "Media",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 12,
    name: "Communications",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 13,
    name: "Energy",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 14,
    name: "Technology",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 15,
    name: "Other",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
];

const teamList = [
  {
    Key: "PTCI-4938242",
    "Team Name": "Accel",
    Nickname: "Accel",
    Status: "Inactive",
    Manager: "Cornel Dobrescu",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Processing",
    "Team Size": "5.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "25/May/21 4:41 AM",
    Updated: "27/Jan/23 12:09 PM",
    id: 1,
  },
  {
    Key: "PTCI-4749186",
    "Team Name": "ADLS Migrations",
    Nickname: "Nimbostratus",
    Status: "Active",
    Manager: "Michal Koltowski",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "6.0",
    "Team Email Address": "nimbostratus@kcura.onmicrosoft.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "14/Apr/21 11:05 AM",
    Updated: "26/Jan/23 5:43 PM",
    id: 2,
  },
  {
    Key: "PTCI-3683210",
    "Team Name": "Admin1",
    Nickname: "Admin1",
    Status: "Active",
    Manager: "Piotr Blaz",
    Department: "Engineering",
    Domain: "Matter Administration",
    "Sub Domain": "Matter Administration > Matter/Workspace Setup",
    "Team Size": "5.0",
    "Team Email Address": "Admin1@kcura.onmicrosoft.com",
    "Public Slack Channel": "#help-platform",
    "Pager Duty Escalation Service": "Platform - Admin1 Escalations",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "15/Sep/20 1:07 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 3,
  },
  {
    Key: "PTCI-4938244",
    "Team Name": "ADS Deploy",
    Nickname: "ADS Deploy",
    Status: "Active",
    Manager: "Zehra Mecci",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Automation and UI Enablement",
    "Team Size": "6.0",
    "Team Email Address": "ADS_deploy@relativity.com",
    "Public Slack Channel": "#help-platform",
    "Pager Duty Escalation Service": "ADS Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "25/May/21 4:41 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 4,
  },
  {
    Key: "PTCI-4938243",
    "Team Name": "ADS Install",
    Nickname: "ADS Install",
    Status: "Active",
    Manager: "Zehra Mecci",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Automation and UI Enablement",
    "Team Size": "6.0",
    "Team Email Address": "ADS_install@relativity.com",
    "Public Slack Channel": "#help-platform",
    "Pager Duty Escalation Service": "ADS Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "25/May/21 4:41 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 5,
  },
  {
    Key: "PTCI-6131670",
    "Team Name": "AI Classification",
    Nickname: "AI Classification",
    Status: "Active",
    Manager: "Aaron Ogan",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > AI Experience",
    "Team Size": "8.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "AI Services - AI Classification Service",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "15/Dec/21 11:05 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 6,
  },
  {
    Key: "PTCI-6158963",
    "Team Name": "AI Engineering Research",
    Nickname: "AI Engineering Research",
    Status: "Inactive",
    Manager: "Ken Dallmeyer",
    Department: "Engineering",
    Domain: "Data Services",
    "Sub Domain": "Data Services > ML Ops",
    "Team Size": "4.0",
    "Team Email Address": "ai-principal-and-staff@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "03/Jan/22 4:48 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 7,
  },
  {
    Key: "PTCI-1370",
    "Team Name": "AI Enrichment",
    Nickname: "AI Enrichment",
    Status: "Active",
    Manager: "Robert Cucinotta",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > AI Enrichment",
    "Team Size": "9.0",
    "Team Email Address": "Bebop@relativity.com",
    "Public Slack Channel": "#help-analytics",
    "Pager Duty Escalation Service": "Structured Analytics Escalation",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 8,
  },
  {
    Key: "PTCI-3663392",
    "Team Name": "AI Platform",
    Nickname: "AI Platform",
    Status: "Active",
    Manager: "Sanmeet Kanhere",
    Department: "Engineering",
    Domain: "Data Services",
    "Sub Domain": "Data Services > ML Ops",
    "Team Size": "6.0",
    "Team Email Address": "ai-platform@relativity.com",
    "Public Slack Channel": "#help-ai-platform",
    "Pager Duty Escalation Service": "Analytics Upgrades Escalation",
    SprintMailType: "Kanban",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "04/Aug/20 9:12 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 9,
  },
  {
    Key: "PTCI-6164491",
    "Team Name": "API Enablement",
    Nickname: "API Enablement",
    Status: "Active",
    Manager: "Thor Biedrzycki",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Extensibility Services",
    "Team Size": "2.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "07/Jan/22 8:56 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 10,
  },
  {
    Key: "PTCI-1360",
    "Team Name": "Apollo",
    Nickname: "Apollo",
    Status: "Inactive",
    Manager: "Geoff Uvena",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Processing",
    "Team Size": "4.0",
    "Team Email Address": "Apollo@relativity.com",
    "Public Slack Channel": "#help-processing",
    "Pager Duty Escalation Service": "Processing -  Discovery, Inventory",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 4:16 PM",
    id: 11,
  },
  {
    Key: "PTCI-4141200",
    "Team Name": "Aqua Viewer",
    Nickname: "Kingfisher",
    Status: "Active",
    Manager: "John Womeldorff",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > Review",
    "Team Size": "5.0",
    "Team Email Address": "kingfishers@relativity.com",
    "Public Slack Channel": "#help-outsidein",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "20/Jan/21 1:00 AM",
    Updated: "27/Jan/23 9:35 AM",
    id: 12,
  },
  {
    Key: "PTCI-889350",
    "Team Name": "Aspera",
    Nickname: "Aspartans",
    Status: "Active",
    Manager: "Anna Kaim",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "4.0",
    "Team Email Address": "aspartans@relativity.com",
    "Public Slack Channel": "#help-aspera",
    "Pager Duty Escalation Service": "Aspera Escalations",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "11/Jan/20 1:00 AM",
    Updated: "27/Jan/23 2:03 PM",
    id: 13,
  },
  {
    Key: "PTCI-5429238",
    "Team Name": "Audit",
    Nickname: "Audit",
    Status: "Active",
    Manager: "Michal Janda",
    Department: "Engineering",
    Domain: "Data Services",
    "Sub Domain": "Data Services > Data Ops",
    "Team Size": "5.0",
    "Team Email Address": "audit@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Audit Escalation Service",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "21/Jul/21 8:16 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 14,
  },
  {
    Key: "PTCI-4261010",
    "Team Name": "Auth",
    Nickname: "Auth",
    Status: "Active",
    Manager: "Michael Masanek",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Identity Services",
    "Team Size": "7.0",
    "Team Email Address": "authteam@relativity.com",
    "Public Slack Channel": "#help-platform",
    "Pager Duty Escalation Service": "Platform - Auth Escalation",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "13/Feb/21 2:49 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 15,
  },
  {
    Key: "PTCI-1371",
    "Team Name": "Automated Workflows",
    Nickname: "Benderbrau",
    Status: "Active",
    Manager: "Pablo Bernal",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Automation and UI Enablement",
    "Team Size": "4.0",
    "Team Email Address": "Benderbrau@relativity.com",
    "Public Slack Channel": "#help-automated-workflows",
    "Pager Duty Escalation Service": "Automated Workflow Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 5:12 PM",
    id: 16,
  },
  {
    Key: "PTCI-1368",
    "Team Name": "Aviato",
    Nickname: "Aviato",
    Status: "Active",
    Manager: "Cliff Rhyne",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Transformation",
    "Team Size": "3.0",
    "Team Email Address": "aviato@kcura.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Productions Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 17,
  },
  {
    Key: "PTCI-1374",
    "Team Name": "Binford Tools",
    Nickname: "Binford Tools",
    Status: "Inactive",
    Manager: "Casey Ford",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Engineering Systems",
    "Team Size": "5.0",
    "Team Email Address": "binfordtools@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Trident Infrastructure Services",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 18,
  },
  {
    Key: "PTCI-3233730",
    "Team Name": "Boardwalk",
    Nickname: "Boardwalk",
    Status: "Active",
    Manager: "Jim Witte",
    Department: "Engineering",
    Domain: "Patents",
    "Sub Domain": "",
    "Team Size": "4.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Boardwalk (Patent Search)",
    SprintMailType: "None",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "09/May/20 1:00 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 19,
  },
  {
    Key: "PTCI-1627",
    "Team Name": "C4 Platform",
    Nickname: "_underscore",
    Status: "Active",
    Manager: "Steve Sadowski",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "3.0",
    "Team Email Address": "clowder@kcura.com",
    "Public Slack Channel": "#clowder",
    "Pager Duty Escalation Service": "C4 Escalations",
    SprintMailType: "Kanban",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 6:04 PM",
    id: 20,
  },
  {
    Key: "PTCI-1381",
    "Team Name": "Case Strategy",
    Nickname: "Chaos Monkeys",
    Status: "Active",
    Manager: "Can Zhen",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > Case Strategy",
    "Team Size": "7.0",
    "Team Email Address": "chaosmonkeys@relativity.com",
    "Public Slack Channel": "#help-casedynamics-transcripts",
    "Pager Duty Escalation Service": "Case Strategy Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 12:45 PM",
    id: 21,
  },
  {
    Key: "PTCI-1354",
    "Team Name": "Collect",
    Nickname: "1-800-COLLECT",
    Status: "Active",
    Manager: "Kishore Rao",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Collect",
    "Team Size": "6.0",
    "Team Email Address": "",
    "Public Slack Channel": "#help-collect",
    "Pager Duty Escalation Service": "Collection Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 5:40 PM",
    id: 22,
  },
  {
    Key: "PTCI-889357",
    "Team Name": "Configuration Services",
    Nickname: "Mighty Configurator",
    Status: "Active",
    Manager: "Tomasz Szmyd",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Engineering Systems",
    "Team Size": "7.0",
    "Team Email Address": "powertools@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service":
      "[Configuration Services] RMS, Toggles, Chef",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "11/Jan/20 1:00 AM",
    Updated: "10/Feb/23 10:17 AM",
    id: 23,
  },
  {
    Key: "PTCI-8218810",
    "Team Name": "Connectors",
    Nickname: "Connectors",
    Status: "Active",
    Manager: "Sunghee Choi",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Collect",
    "Team Size": "5.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "17/Nov/22 1:50 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 24,
  },
  {
    Key: "PTCI-4890553",
    "Team Name": "Connectors Framework",
    Nickname: "VerQu.Core",
    Status: "Active",
    Manager: "Sunghee Choi",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Collect",
    "Team Size": "7.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "VerQuCoreService",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "15/May/21 5:22 AM",
    Updated: "27/Jan/23 12:41 PM",
    id: 25,
  },
  {
    Key: "PTCI-1619",
    "Team Name": "Container Extraction",
    Nickname: "Wookiees",
    Status: "Active",
    Manager: "Cornel Dobrescu",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Processing",
    "Team Size": "5.0",
    "Team Email Address": "Wookiees@kcura.com",
    "Public Slack Channel": "#help-processing",
    "Pager Duty Escalation Service":
      "Processing - R1 Infrastructure, ARM, Invariant Installer, Servers, RPC",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 12:47 PM",
    id: 26,
  },
  {
    Key: "PTCI-7948521",
    "Team Name": "Contracts",
    Nickname: "Contracts",
    Status: "Active",
    Manager: "Karthik Muthupalaniappan",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > Contracts",
    "Team Size": "3.0",
    "Team Email Address": "contracts-engineering@relativity.com",
    "Public Slack Channel": "#help-contracts",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "01/Sep/22 3:27 PM",
    Updated: "31/Jan/23 11:07 AM",
    id: 27,
  },
  {
    Key: "PTCI-1599",
    "Team Name": "Core Toolchain",
    Nickname: "Core Toolchain",
    Status: "Active",
    Manager: "Casey Ford",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Engineering Systems",
    "Team Size": "2.0",
    "Team Email Address": "coretoolchain@relativity.com",
    "Public Slack Channel":
      "#proj-software-catalog, #help-relativity-testing-framework, #help-codescene #cop-devops",
    "Pager Duty Escalation Service": "Trident Infrastructure Services",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 28,
  },
  {
    Key: "PTCI-5978220",
    "Team Name": "Data Visualizations",
    Nickname: "Chart Week",
    Status: "Active",
    Manager: "Alex Taranukha",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > AI Experience",
    "Team Size": "5.0",
    "Team Email Address": "gridplatformteam@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Data Grid Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "22/Oct/21 4:57 AM",
    Updated: "27/Jan/23 5:40 PM",
    id: 29,
  },
  {
    Key: "PTCI-6131656",
    "Team Name": "Design System",
    Nickname: "Lego",
    Status: "Active",
    Manager: "Pablo Bernal",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Automation and UI Enablement",
    "Team Size": "5.0",
    "Team Email Address": "lego@relativity.com",
    "Public Slack Channel": "#help-rwc-rwa-design-system",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "15/Dec/21 9:19 AM",
    Updated: "26/Jan/23 5:10 PM",
    id: 30,
  },
  {
    Key: "PTCI-1446",
    "Team Name": "Developer Environments",
    Nickname: "Developer Environments",
    Status: "Active",
    Manager: "Parimal Patel",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Engineering Systems",
    "Team Size": "5.0",
    "Team Email Address": "homeimprovement@relativity.com",
    "Public Slack Channel":
      "#help-hopper #help-testdk #help-testvmcommunity #news-devvm",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 31,
  },
  {
    Key: "PTCI-1426",
    "Team Name": "Emosewa",
    Nickname: "Emosewa",
    Status: "Active",
    Manager: "Cliff Rhyne",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Transformation",
    "Team Size": "4.0",
    "Team Email Address": "emosewa@kcura.com",
    "Public Slack Channel": "#help-productions",
    "Pager Duty Escalation Service": "Productions Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 32,
  },
  {
    Key: "PTCI-2897503",
    "Team Name": "Eventing & Messaging",
    Nickname: "Eventing & Messaging",
    Status: "Active",
    Manager: "Thor Biedrzycki",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Extensibility Services",
    "Team Size": "5.0",
    "Team Email Address": "help-eventing-messaging@relativity.com",
    "Public Slack Channel": "#help-eventing-and-messaging",
    "Pager Duty Escalation Service":
      "Platform - Eventing & Messaging Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Apr/20 1:00 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 33,
  },
  {
    Key: "PTCI-6071442",
    "Team Name": "Express Transfer",
    Nickname: "Skynet",
    Status: "Active",
    Manager: "Daniel Wysocki",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Data Transfer",
    "Team Size": "5.0",
    "Team Email Address": "skynet@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Express Transfer Escalation Service",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "16/Nov/21 9:55 AM",
    Updated: "26/Jan/23 5:36 PM",
    id: 34,
  },
  {
    Key: "PTCI-1593",
    "Team Name": "File Extraction",
    Nickname: "TARDIS",
    Status: "Active",
    Manager: "Matthew Rhoades",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Processing",
    "Team Size": "4.0",
    "Team Email Address": "Tardis@relativity.com",
    "Public Slack Channel": "#help-processing",
    "Pager Duty Escalation Service": "Processing -  Discovery, Inventory",
    SprintMailType: "Kanban",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 12:47 PM",
    id: 35,
  },
  {
    Key: "PTCI-3634191",
    "Team Name": "Forms and Pagebase",
    Nickname: "Test1234!",
    Status: "Active",
    Manager: "David Pionke",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Automation and UI Enablement",
    "Team Size": "5.0",
    "Team Email Address": "Test1234!@relativity.com",
    "Public Slack Channel": "#team-test1234-public",
    "Pager Duty Escalation Service": "Relativity Forms (Liquid) Escalation",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "11/Jun/20 1:00 AM",
    Updated: "26/Jan/23 5:52 PM",
    id: 36,
  },
  {
    Key: "PTCI-7547539",
    "Team Name": "Fusion",
    Nickname: "Fusion",
    Status: "Active",
    Manager: "Oleg Rybkin",
    Department: "Engineering",
    Domain: "Discovery+",
    "Sub Domain": "Discovery+ > Privacy",
    "Team Size": "6.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "10/May/22 5:16 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 37,
  },
  {
    Key: "PTCI-4692801",
    "Team Name": "Gravity",
    Nickname: "Gravity",
    Status: "Inactive",
    Manager: "Cornel Dobrescu",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Processing",
    "Team Size": "5.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "01/Apr/21 1:00 AM",
    Updated: "27/Jan/23 12:09 PM",
    id: 38,
  },
  {
    Key: "PTCI-4890552",
    "Team Name": "Hive",
    Nickname: "Hive",
    Status: "Inactive",
    Manager: "Sunghee Choi",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Collect",
    "Team Size": "0.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "15/May/21 5:22 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 39,
  },
  {
    Key: "PTCI-759467",
    "Team Name": "Hosting",
    Nickname: "Hosting",
    Status: "Active",
    Manager: "Steve Keller",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Extensibility Services",
    "Team Size": "7.0",
    "Team Email Address": "help-hosting@relativity.com",
    "Public Slack Channel": "#help-extensibility #help-agents-k8s-cop",
    "Pager Duty Escalation Service": "Platform - Hosting Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "07/Jan/20 1:00 AM",
    Updated: "09/Feb/23 8:45 AM",
    id: 40,
  },
  {
    Key: "PTCI-1488",
    "Team Name": "Imaging",
    Nickname: "Mars",
    Status: "Active",
    Manager: "Craig Tucker",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Transformation",
    "Team Size": "4.0",
    "Team Email Address": "Mars@relativity.com",
    "Public Slack Channel": "#help-imaging-and-pdf",
    "Pager Duty Escalation Service": "Imaging Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 12:43 PM",
    id: 41,
  },
  {
    Key: "PTCI-4224858",
    "Team Name": "Import Export",
    Nickname: "Mozzarella Firefoxes",
    Status: "Active",
    Manager: "Dariusz Chyla",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Data Transfer",
    "Team Size": "9.0",
    "Team Email Address": "mozzarellafirefoxes@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service":
      "Import-Export Application Escalation Service",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "03/Feb/21 1:00 AM",
    Updated: "27/Jan/23 1:26 PM",
    id: 42,
  },
  {
    Key: "PTCI-4941411",
    "Team Name": "Import Export API",
    Nickname: "Holy Data Acquisition",
    Status: "Active",
    Manager: "Lukasz Obratanski",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Data Transfer",
    "Team Size": "9.0",
    "Team Email Address": "holydataacquisition@relativity.com",
    "Public Slack Channel": "#help-iapi #help-rdc",
    "Pager Duty Escalation Service": "Import API and RDC Escalation",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "26/May/21 1:00 AM",
    Updated: "26/Jan/23 5:50 PM",
    id: 43,
  },
  {
    Key: "PTCI-6578175",
    "Team Name": "Investigations",
    Nickname: "Investigations",
    Status: "Inactive",
    Manager: "Kevin Opperman",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Automation and UI Enablement",
    "Team Size": "4.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "23/Feb/22 6:31 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 44,
  },
  {
    Key: "PTCI-2542307",
    "Team Name": "Kubernauts",
    Nickname: "Kubernauts",
    Status: "Active",
    Manager: "Steve Sadowski",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "6.0",
    "Team Email Address": "kubernauts@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Shared Compute Services",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "19/Mar/20 1:00 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 45,
  },
  {
    Key: "PTCI-4793505",
    "Team Name": "KubernetesPL",
    Nickname: "CloudMetis",
    Status: "Active",
    Manager: "Anna Kaim",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "4.0",
    "Team Email Address": "CloudMetis@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Shared Compute Services",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "24/Apr/21 1:00 AM",
    Updated: "07/Feb/23 8:29 AM",
    id: 46,
  },
  {
    Key: "PTCI-1438",
    "Team Name": "Legal Hold",
    Nickname: "Game of Holds",
    Status: "Active",
    Manager: "Jonas Cyvas",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Legal Hold - PiP",
    "Team Size": "6.0",
    "Team Email Address": "GameOfHolds@relativity.com",
    "Public Slack Channel": "#team-gameofholds",
    "Pager Duty Escalation Service": "Legal Hold Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 5:34 PM",
    id: 47,
  },
  {
    Key: "PTCI-1475",
    "Team Name": "Lists and Home Page",
    Nickname: "Jiggawatts",
    Status: "Active",
    Manager: "Ahmed Ijaz",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > AI Experience",
    "Team Size": "8.0",
    "Team Email Address": "Jiggawatts@relativity.com",
    "Public Slack Channel": "#help-relativity-list-page #help-homepage",
    "Pager Duty Escalation Service": "List Page Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 12:39 PM",
    id: 48,
  },
  {
    Key: "PTCI-1362",
    "Team Name": "Matter ARM",
    Nickname: "Armageddon",
    Status: "Active",
    Manager: "Lukasz Dobrowolski",
    Department: "Engineering",
    Domain: "Matter Administration",
    "Sub Domain": "Matter Administration > Workspace Movement",
    "Team Size": "9.0",
    "Team Email Address": "armageddon@relativity.com",
    "Public Slack Channel": "#help-arm",
    "Pager Duty Escalation Service": "ARM Incident  Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "06/Feb/23 9:37 AM",
    id: 49,
  },
  {
    Key: "PTCI-3681931",
    "Team Name": "Message Extraction",
    Nickname: "TraceX",
    Status: "Active",
    Manager: "Krzysztof Kukla",
    Department: "Engineering",
    Domain: "Discovery+",
    "Sub Domain": "Discovery+ > Trace",
    "Team Size": "10.0",
    "Team Email Address": "tracex@relativity.com",
    "Public Slack Channel": "#help-trace",
    "Pager Duty Escalation Service": "",
    SprintMailType: "None",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "12/Sep/20 1:01 AM",
    Updated: "27/Jan/23 1:27 PM",
    id: 50,
  },
  {
    Key: "PTCI-4318695",
    "Team Name": "Milyli (Blackout)",
    Nickname: "Milyli (Blackout)",
    Status: "Active",
    Manager: "Krishna Aerabati",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > Review",
    "Team Size": "10.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "None",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "17/Feb/21 5:13 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 51,
  },
  {
    Key: "PTCI-1495",
    "Team Name": "Milyli-Collect",
    Nickname: "Milyli-Collect",
    Status: "Active",
    Manager: "Kishore Rao",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Collect",
    "Team Size": "",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "None",
    COPQFlag: "",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 52,
  },
  {
    Key: "PTCI-6126766",
    "Team Name": "ML Ops",
    Nickname: "AI Enterprise",
    Status: "Active",
    Manager: "Aparna Korattyswaroopam",
    Department: "Engineering",
    Domain: "Data Services",
    "Sub Domain": "Data Services > ML Ops",
    "Team Size": "4.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "13/Dec/21 3:36 PM",
    Updated: "26/Jan/23 5:38 PM",
    id: 53,
  },
  {
    Key: "PTCI-1498",
    "Team Name": "Mobile",
    Nickname: "Mobile",
    Status: "Active",
    Manager: "Piotr Blaz",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > Matter Management",
    "Team Size": "4.0",
    "Team Email Address": "mobile@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Mobile Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "03/Feb/23 9:55 AM",
    id: 54,
  },
  {
    Key: "PTCI-787199",
    "Team Name": "Moonshiners",
    Nickname: "Moonshiners",
    Status: "Active",
    Manager: "Lukasz Dobrowolski",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Data Transfer",
    "Team Size": "2.0",
    "Team Email Address": "moonshiners@relativity.com",
    "Public Slack Channel": "#help-migrate",
    "Pager Duty Escalation Service": "Migrate Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "08/Jan/20 1:00 AM",
    Updated: "06/Feb/23 9:37 AM",
    id: 55,
  },
  {
    Key: "PTCI-1502",
    "Team Name": "Moonwalkers-Callisto",
    Nickname: "",
    Status: "Inactive",
    Manager: "Syamkumar Abburi",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "",
    "Team Size": "6.0",
    "Team Email Address": "Moonwalkers@relativity.com",
    "Public Slack Channel": "#help-analytics",
    "Pager Duty Escalation Service":
      "AI Experience - Centralized Review Service",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "19/Jan/23 3:14 PM",
    id: 56,
  },
  {
    Key: "PTCI-1504",
    "Team Name": "Moonwalkers-Io",
    Nickname: "",
    Status: "Inactive",
    Manager: "Aaron Ogan",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "",
    "Team Size": "5.0",
    "Team Email Address": "Moonwalkers@relativity.com",
    "Public Slack Channel": "#help-analytics",
    "Pager Duty Escalation Service":
      "AI Experience - Centralized Review Service",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "19/Jan/23 3:14 PM",
    id: 57,
  },
  {
    Key: "PTCI-1506",
    "Team Name": "Moonwalkers-Triton",
    Nickname: "",
    Status: "Inactive",
    Manager: "Aaron Ogan",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "",
    "Team Size": "5.0",
    "Team Email Address": "Moonwalkers@relativity.com",
    "Public Slack Channel": "#help-analytics",
    "Pager Duty Escalation Service":
      "AI Experience - Centralized Review Service",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "19/Jan/23 3:14 PM",
    id: 58,
  },
  {
    Key: "PTCI-4339708",
    "Team Name": "Observability-Analyze",
    Nickname: "Observability-Analyze",
    Status: "Active",
    Manager: "Peter Farcasanu",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > SRE & Observability",
    "Team Size": "6.0",
    "Team Email Address": "lucid@relativity.com",
    "Public Slack Channel": "#help-monitoring",
    "Pager Duty Escalation Service": "Observability Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "24/Feb/21 4:40 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 59,
  },
  {
    Key: "PTCI-4318696",
    "Team Name": "Observability-Ingest",
    Nickname: "Observability-Ingest",
    Status: "Active",
    Manager: "Peter Farcasanu",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > SRE & Observability",
    "Team Size": "4.0",
    "Team Email Address": "lucid@relativity.com",
    "Public Slack Channel": "#help-monitoring",
    "Pager Duty Escalation Service": "Observability Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "17/Feb/21 5:13 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 60,
  },
  {
    Key: "PTCI-5551758",
    "Team Name": "Observability-Monitor",
    Nickname: "Watchmen",
    Status: "Active",
    Manager: "Anna Kaim",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "2.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "27/Aug/21 4:47 PM",
    Updated: "09/Feb/23 9:08 AM",
    id: 61,
  },
  {
    Key: "PTCI-1465",
    "Team Name": "OCR",
    Nickname: "ORCs",
    Status: "Active",
    Manager: "Craig Tucker",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Transformation",
    "Team Size": "4.0",
    "Team Email Address": "Imagineers@relativity.com",
    "Public Slack Channel": "#help-ocr",
    "Pager Duty Escalation Service": "Imaging Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 12:42 PM",
    id: 62,
  },
  {
    Key: "PTCI-1355",
    "Team Name": "Orchestration Systems",
    Nickname: "Orchestration Systems",
    Status: "Active",
    Manager: "Justin Smits",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Engineering Systems",
    "Team Size": "7.0",
    "Team Email Address": "OrchSys@Relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Orchestration System Services",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 63,
  },
  {
    Key: "PTCI-1432",
    "Team Name": "Outside In Transformation",
    Nickname: "Fawkes",
    Status: "Active",
    Manager: "Vince Raso",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Transformation",
    "Team Size": "6.0",
    "Team Email Address": "fawkes@relativity.com",
    "Public Slack Channel": "#help-outsidein",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 5:18 PM",
    id: 64,
  },
  {
    Key: "PTCI-3637088",
    "Team Name": "Permissions",
    Nickname: "Permissions",
    Status: "Active",
    Manager: "Piotr Kus",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Identity Services",
    "Team Size": "6.0",
    "Team Email Address": "permissions@relativity.com",
    "Public Slack Channel": "#help-permissions",
    "Pager Duty Escalation Service": "Platform - Permissions Escalations",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "25/Jun/20 1:00 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 65,
  },
  {
    Key: "PTCI-6131948",
    "Team Name": "Privilege Panda",
    Nickname: "Panda",
    Status: "Active",
    Manager: "Marco Ravaglia",
    Department: "Engineering",
    Domain: "Discovery+",
    "Sub Domain": "Discovery+ > Privilege",
    "Team Size": "3.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "15/Dec/21 2:10 PM",
    Updated: "26/Jan/23 5:45 PM",
    id: 66,
  },
  {
    Key: "PTCI-7547538",
    "Team Name": "Privilege Phoenix",
    Nickname: "Phoenix",
    Status: "Active",
    Manager: "Chengbo Li",
    Department: "Engineering",
    Domain: "Discovery+",
    "Sub Domain": "Discovery+ > Privilege",
    "Team Size": "1.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "10/May/22 5:16 PM",
    Updated: "26/Jan/23 5:45 PM",
    id: 67,
  },
  {
    Key: "PTCI-1529",
    "Team Name": "Processing ARM and Infrastructure",
    Nickname: "Planet Express",
    Status: "Active",
    Manager: "Ryan Grattoni",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Processing",
    "Team Size": "4.0",
    "Team Email Address": "planetExpress@relativity.com",
    "Public Slack Channel": "#help-planet-express",
    "Pager Duty Escalation Service":
      "Processing - Publish, Agents, Application",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 1:25 PM",
    id: 68,
  },
  {
    Key: "PTCI-1352",
    "Team Name": "Processing Front End",
    Nickname: "Aethon",
    Status: "Active",
    Manager: "Geoff Uvena",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Processing",
    "Team Size": "4.0",
    "Team Email Address": "aethon@relativity.com",
    "Public Slack Channel": "#help-processing",
    "Pager Duty Escalation Service":
      "Processing - Publish, Agents, Application",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 12:34 PM",
    id: 69,
  },
  {
    Key: "PTCI-1626",
    "Team Name": "Processing Publish",
    Nickname: "Wyverns",
    Status: "Active",
    Manager: "Geoff Uvena",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Processing",
    "Team Size": "4.0",
    "Team Email Address": "Wyverns@relativity.com",
    "Public Slack Channel": "#help-processing",
    "Pager Duty Escalation Service":
      "Processing - Publish, Agents, Application",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 6:05 PM",
    id: 70,
  },
  {
    Key: "PTCI-1444",
    "Team Name": "Processing Worker Compute",
    Nickname: "Heisenberg",
    Status: "Active",
    Manager: "Ryan Grattoni",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Processing",
    "Team Size": "5.0",
    "Team Email Address": "Heisenberg@kcura.com",
    "Public Slack Channel": "#help-processing",
    "Pager Duty Escalation Service":
      "Processing - R1 Infrastructure, ARM, Invariant Installer, Servers, RPC",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 1:15 PM",
    id: 71,
  },
  {
    Key: "PTCI-6053723",
    "Team Name": "Purview Sync",
    Nickname: "Outliers",
    Status: "Active",
    Manager: "Kishore Rao",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Legal Hold - PiP",
    "Team Size": "1.0",
    "Team Email Address": "outliers@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "10/Nov/21 4:13 AM",
    Updated: "26/Jan/23 5:42 PM",
    id: 72,
  },
  {
    Key: "PTCI-1548",
    "Team Name": "R1 Network",
    Nickname: "R1 Network",
    Status: "Active",
    Manager: "Chris Stolp",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "4.0",
    "Team Email Address": "r1-network@relativity.com",
    "Public Slack Channel": "#help-r1-network",
    "Pager Duty Escalation Service": "R1Network Incidents (P1-P2)",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 73,
  },
  {
    Key: "PTCI-1517",
    "Team Name": "RDO Team",
    Nickname: "RDO Team",
    Status: "Active",
    Manager: "Lazaro Sanchez",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Extensibility Services",
    "Team Size": "7.0",
    "Team Email Address": "RDO_Team@relativity.com",
    "Public Slack Channel": "#help-automation-enablement",
    "Pager Duty Escalation Service":
      "Platform - Object Manager Group Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "31/Jan/23 5:18 AM",
    id: 74,
  },
  {
    Key: "PTCI-6549352",
    "Team Name": "Reporting",
    Nickname: "Reporting",
    Status: "Active",
    Manager: "Amy Alley",
    Department: "Engineering",
    Domain: "Data Services",
    "Sub Domain": "Data Services > Data Ops",
    "Team Size": "6.0",
    "Team Email Address": "",
    "Public Slack Channel": "#help-platform",
    "Pager Duty Escalation Service":
      "Platform - Reporting Team Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "08/Feb/22 3:13 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 75,
  },
  {
    Key: "PTCI-6131949",
    "Team Name": "Reuben",
    Nickname: "Reuben",
    Status: "Active",
    Manager: "Maxine Chan",
    Department: "Engineering",
    Domain: "Discovery+",
    "Sub Domain": "Discovery+ > Privacy",
    "Team Size": "9.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "15/Dec/21 2:12 PM",
    Updated: "08/Feb/23 9:07 AM",
    id: 76,
  },
  {
    Key: "PTCI-1445",
    "Team Name": "Review - AI Integrations",
    Nickname: "Hogwash",
    Status: "Active",
    Manager: "John Womeldorff",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > Review",
    "Team Size": "4.0",
    "Team Email Address": "Hogwash@kcura.com",
    "Public Slack Channel": "#help-review",
    "Pager Duty Escalation Service": "Review Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "30/Jan/23 11:45 AM",
    id: 77,
  },
  {
    Key: "PTCI-1586",
    "Team Name": "Review - Redact & Short Message",
    Nickname: "ShipitParrots",
    Status: "Active",
    Manager: "Karthik Muthupalaniappan",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > Review",
    "Team Size": "4.0",
    "Team Email Address": "shipitparrots@relativity.com",
    "Public Slack Channel": "#help-review",
    "Pager Duty Escalation Service": "Review Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 12:46 PM",
    id: 78,
  },
  {
    Key: "PTCI-6131666",
    "Team Name": "Review Center 1",
    Nickname: "Centralized Review 1",
    Status: "Active",
    Manager: "Aaron Ogan",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > AI Experience",
    "Team Size": "5.0",
    "Team Email Address": "centralized-review-1@relativity.com",
    "Public Slack Channel": "#help-ai-experience",
    "Pager Duty Escalation Service":
      "AI Experience - Centralized Review Service",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "15/Dec/21 11:04 AM",
    Updated: "26/Jan/23 5:53 PM",
    id: 79,
  },
  {
    Key: "PTCI-6131667",
    "Team Name": "Review Center 2",
    Nickname: "Centralized Review 2",
    Status: "Active",
    Manager: "Aaron Ogan",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > AI Experience",
    "Team Size": "5.0",
    "Team Email Address": "centralized-review-2@relativity.com",
    "Public Slack Channel": "#help-ai-experience",
    "Pager Duty Escalation Service":
      "AI Experience - Centralized Review Service",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "15/Dec/21 11:04 AM",
    Updated: "26/Jan/23 5:54 PM",
    id: 80,
  },
  {
    Key: "PTCI-2456712",
    "Team Name": "RIP and SFU",
    Nickname: "Adler Sieben",
    Status: "Active",
    Manager: "Radoslaw Olkowicz",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Data Transfer",
    "Team Size": "6.5",
    "Team Email Address": "adlersieben@relativity.com",
    "Public Slack Channel": "#help-rip #help-sfu",
    "Pager Duty Escalation Service": "RIP SFU Escalation",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "12/Mar/20 1:00 AM",
    Updated: "27/Jan/23 12:44 PM",
    id: 81,
  },
  {
    Key: "PTCI-1392",
    "Team Name": "ROSE",
    Nickname: "Commodores",
    Status: "Active",
    Manager: "Avichay Kardash",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Data Transfer",
    "Team Size": "3.0",
    "Team Email Address": "commodores@relativity.com",
    "Public Slack Channel": "#help-rose",
    "Pager Duty Escalation Service": "ROSE and TAPI Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 5:59 PM",
    id: 82,
  },
  {
    Key: "PTCI-1559",
    "Team Name": "Rubber Duckies",
    Nickname: "Rubber Duckies",
    Status: "Active",
    Manager: "John Womeldorff",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > Review",
    "Team Size": "4.0",
    "Team Email Address": "rubberduckies@relativity.com",
    "Public Slack Channel": "#help-review",
    "Pager Duty Escalation Service": "Review Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 83,
  },
  {
    Key: "PTCI-1584",
    "Team Name": "Search Data",
    Nickname: "Sharktopus",
    Status: "Active",
    Manager: "Alex Taranukha",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > AI Experience",
    "Team Size": "5.0",
    "Team Email Address": "gridplatformteam@relativity.com",
    "Public Slack Channel": "#help-search",
    "Pager Duty Escalation Service": "Data Grid Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 2:02 PM",
    id: 84,
  },
  {
    Key: "PTCI-1565",
    "Team Name": "Search Experience",
    Nickname: "Search-and-Destroy",
    Status: "Active",
    Manager: "Mike Markzon",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > AI Experience",
    "Team Size": "5.0",
    "Team Email Address": "gridplatformteam@relativity.com",
    "Public Slack Channel": "#help-search",
    "Pager Duty Escalation Service": "Data Grid Incident Support",
    SprintMailType: "None",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "27/Jan/23 12:40 PM",
    id: 85,
  },
  {
    Key: "PTCI-787200",
    "Team Name": "Server Architecture Team",
    Nickname: "Server Architecture Team",
    Status: "Active",
    Manager: "Gosia Siegenfeld",
    Department: "Engineering",
    Domain: "Relativity Server",
    "Sub Domain": "Relativity Server > Platform Services",
    "Team Size": "6.0",
    "Team Email Address": "serverarchitectureteam@relativity.com",
    "Public Slack Channel": "#help-server-architecture",
    "Pager Duty Escalation Service": "Server Engineering Service",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "08/Jan/20 1:00 AM",
    Updated: "30/Jan/23 10:29 AM",
    id: 86,
  },
  {
    Key: "PTCI-2444638",
    "Team Name": "Server Release Management",
    Nickname: "Server Release Management",
    Status: "Active",
    Manager: "Gosia Siegenfeld",
    Department: "Engineering",
    Domain: "Relativity Server",
    "Sub Domain": "Relativity Server > Platform Services",
    "Team Size": "1.0",
    "Team Email Address": "serverreleasemanagement@relativity.com",
    "Public Slack Channel": "#help-server-release-management",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "11/Mar/20 1:00 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 87,
  },
  {
    Key: "PTCI-7611207",
    "Team Name": "Server Team - Review",
    Nickname: "Server Team - Review",
    Status: "Active",
    Manager: "Berhane Zewdie",
    Department: "Engineering",
    Domain: "Relativity Server",
    "Sub Domain": "Relativity Server > Platform Services",
    "Team Size": "7.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "26/May/22 9:02 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 88,
  },
  {
    Key: "PTCI-6548551",
    "Team Name": "Server Tools",
    Nickname: "Server Tools",
    Status: "Inactive",
    Manager: "Gosia Siegenfeld",
    Department: "Engineering",
    Domain: "Relativity Server",
    "Sub Domain": "Relativity Server > Platform Services",
    "Team Size": "2.0",
    "Team Email Address": "help-servertools@relativity.com",
    "Public Slack Channel": "#help-servertools",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "07/Feb/22 10:03 AM",
    Updated: "08/Feb/23 5:21 PM",
    id: 89,
  },
  {
    Key: "PTCI-8275111",
    "Team Name": "SQL PaaS - Control Plane - Infrastructure",
    Nickname: "SQL PaaS - Control Plane - Infrastructure",
    Status: "Inactive",
    Manager: "William Klibanow",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "30/Nov/22 8:08 AM",
    Updated: "27/Jan/23 12:13 PM",
    id: 90,
  },
  {
    Key: "PTCI-8275110",
    "Team Name": "SQL PaaS - Control Plane - Orchestration",
    Nickname: "SQL PaaS - Control Plane - Orchestration",
    Status: "Inactive",
    Manager: "William Klibanow",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "30/Nov/22 8:07 AM",
    Updated: "27/Jan/23 12:13 PM",
    id: 91,
  },
  {
    Key: "PTCI-1372",
    "Team Name": "SQL PaaS BeeKeepers",
    Nickname: "BeeKeepers",
    Status: "Active",
    Manager: "William Klibanow",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "12.0",
    "Team Email Address": "BeeKeepers@relativity.com",
    "Public Slack Channel": "#help-beekeepers",
    "Pager Duty Escalation Service": "SQL PaaS Escalation",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 6:03 PM",
    id: 92,
  },
  {
    Key: "PTCI-1592",
    "Team Name": "Stars",
    Nickname: "Stars",
    Status: "Active",
    Manager: "Cliff Rhyne",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "Enrichment > Transformation",
    "Team Size": "4.0",
    "Team Email Address": "stars@relativity.com",
    "Public Slack Channel": "#help-imaging-and-pdf",
    "Pager Duty Escalation Service": "Imaging Escalation Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 93,
  },
  {
    Key: "PTCI-5647499",
    "Team Name": "Structured Storage Poland",
    Nickname: "Honeycreepers",
    Status: "Active",
    Manager: "Tomek Lorek",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "4.0",
    "Team Email Address": "honeycreepers@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "SQL PaaS Escalation",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "13/Sep/21 11:19 AM",
    Updated: "09/Feb/23 9:08 AM",
    id: 94,
  },
  {
    Key: "PTCI-6131711",
    "Team Name": "Text IQ DevEx Tools and Infra",
    Nickname: "Autobots",
    Status: "Active",
    Manager: "Kunjala Khushboo",
    Department: "Engineering",
    Domain: "Discovery+",
    "Sub Domain": "Discovery+ > Privilege",
    "Team Size": "10.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "15/Dec/21 2:01 PM",
    Updated: "27/Jan/23 1:28 PM",
    id: 95,
  },
  {
    Key: "PTCI-3637089",
    "Team Name": "The Billing",
    Nickname: "The Billing",
    Status: "Active",
    Manager: "Piotr Marczak",
    Department: "Engineering",
    Domain: "Matter Administration",
    "Sub Domain": "Matter Administration > Billing",
    "Team Size": "7.0",
    "Team Email Address": "dataservices@kcura.onmicrosoft.com",
    "Public Slack Channel": "#help-platform",
    "Pager Duty Escalation Service": "The Billing Services Escalations",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "25/Jun/20 1:00 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 96,
  },
  {
    Key: "PTCI-7549794",
    "Team Name": "TIQ Priv IQ 3",
    Nickname: "TIQ Priv IQ 3",
    Status: "Inactive",
    Manager: "Onkar Singh",
    Department: "Engineering",
    Domain: "Discovery+",
    "Sub Domain": "Discovery+ > Privilege",
    "Team Size": "",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "11/May/22 9:23 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 97,
  },
  {
    Key: "PTCI-7549795",
    "Team Name": "TIQ Privacy IQ 3",
    Nickname: "TIQ Privacy IQ 3",
    Status: "Inactive",
    Manager: "Suman Majumder",
    Department: "Engineering",
    Domain: "Discovery+",
    "Sub Domain": "Discovery+ > Privacy",
    "Team Size": "",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "11/May/22 9:24 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 98,
  },
  {
    Key: "PTCI-1479505",
    "Team Name": "ToolTime",
    Nickname: "ToolTime",
    Status: "Inactive",
    Manager: "Casey Ford",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Engineering Systems",
    "Team Size": "6.0",
    "Team Email Address": "tooltime@relativity.com",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "Trident Infrastructure Services",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "01/Feb/20 1:00 AM",
    Updated: "26/Jan/23 4:24 PM",
    id: 99,
  },
  {
    Key: "PTCI-1609",
    "Team Name": "Trace",
    Nickname: "Trace",
    Status: "Active",
    Manager: "Nikita Solilov",
    Department: "Engineering",
    Domain: "Discovery+",
    "Sub Domain": "Discovery+ > Trace",
    "Team Size": "5.0",
    "Team Email Address": "trace@relativity.com",
    "Public Slack Channel": "#help-trace",
    "Pager Duty Escalation Service": "Relativity Trace Incident Support",
    SprintMailType: "None",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 4:24 PM",
    id: 100,
  },
  {
    Key: "PTCI-1611",
    "Team Name": "Trajectory",
    Nickname: "",
    Status: "Inactive",
    Manager: "Syamkumar Abburi",
    Department: "Engineering",
    Domain: "Enrichment",
    "Sub Domain": "",
    "Team Size": "6.0",
    "Team Email Address": "Trajectory@relativity.com",
    "Public Slack Channel": "#help-analytics",
    "Pager Duty Escalation Service": "AI Services - AI Classification Service",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "19/Jan/23 3:14 PM",
  },
  {
    Key: "PTCI-1615",
    "Team Name": "Transfer",
    Nickname: "Unarmed Tapirs",
    Status: "Active",
    Manager: "Avichay Kardash",
    Department: "Engineering",
    Domain: "Ingestion",
    "Sub Domain": "Ingestion > Data Transfer",
    "Team Size": "6.0",
    "Team Email Address": "unarmedtapirs@relativity.com",
    "Public Slack Channel": "#help-tapi",
    "Pager Duty Escalation Service": "ROSE and TAPI Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "26/Jan/23 5:57 PM",
  },
  {
    Key: "PTCI-1429",
    "Team Name": "Unstructured Storage",
    Nickname: "FAST",
    Status: "Active",
    Manager: "Amer Ghazal",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "15.0",
    "Team Email Address": "fast@relativity.com",
    "Public Slack Channel": "#help-fast",
    "Pager Duty Escalation Service": "File PaaS Escalation (FAST)",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "02/Feb/23 1:04 PM",
  },
  {
    Key: "PTCI-4749185",
    "Team Name": "Unstructured Storage Control Plane",
    Nickname: "FAST-CP",
    Status: "Active",
    Manager: "Timothy Kwan",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "5.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "14/Apr/21 11:05 AM",
    Updated: "02/Feb/23 1:04 PM",
  },
  {
    Key: "PTCI-4749184",
    "Team Name": "Unstructured Storage Data Plane",
    Nickname: "FAST-DP",
    Status: "Active",
    Manager: "Amer Ghazal",
    Department: "Engineering",
    Domain: "Infrastructure",
    "Sub Domain": "Infrastructure > Cloud Services",
    "Team Size": "5.0",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "14/Apr/21 11:05 AM",
    Updated: "02/Feb/23 1:04 PM",
  },
  {
    Key: "PTCI-4206221",
    "Team Name": "WebPageRangers",
    Nickname: "WebPageRangers",
    Status: "Active",
    Manager: "Lukasz Dobrowolski",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "Automation Services > Extensibility Services",
    "Team Size": "7.0",
    "Team Email Address": "pagerangers@relativity.com",
    "Public Slack Channel": "#help-platform,  #help-platform-pagerangers",
    "Pager Duty Escalation Service": "Platform - WebPageRangers Services",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "30/Jan/21 1:00 AM",
    Updated: "26/Jan/23 4:24 PM",
  },
  {
    Key: "PTCI-1621",
    "Team Name": "Wild Goose Chasers",
    Nickname: "Wild Goose Chasers",
    Status: "Inactive",
    Manager: "Karthik Muthupalaniappan",
    Department: "Engineering",
    Domain: "Discovery",
    "Sub Domain": "Discovery > Review",
    "Team Size": "3.0",
    "Team Email Address": "wildgoosechasers@relativity.com",
    "Public Slack Channel": "#help-review",
    "Pager Duty Escalation Service": "Review Incident Support",
    SprintMailType: "Scrum",
    COPQFlag: "true",
    Labels: "Dev",
    Created: "16/Dec/19 9:29 PM",
    Updated: "02/Feb/23 11:29 AM",
  },
  {
    Key: "PTCI-8368116",
    "Team Name": "WinWire - 1",
    Nickname: "WinWire - 1",
    Status: "Active",
    Manager: "Sunder Bharadwajan",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "",
    "Team Size": "",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "22/Dec/22 9:22 AM",
    Updated: "26/Jan/23 4:24 PM",
  },
  {
    Key: "PTCI-8368120",
    "Team Name": "WinWire - 2",
    Nickname: "WinWire - 2",
    Status: "Active",
    Manager: "Sunder Bharadwajan",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "",
    "Team Size": "",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "22/Dec/22 9:22 AM",
    Updated: "26/Jan/23 4:24 PM",
  },
  {
    Key: "PTCI-8368124",
    "Team Name": "WinWire - 3",
    Nickname: "WinWire - 3",
    Status: "Active",
    Manager: "Sunder Bharadwajan",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "",
    "Team Size": "",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "22/Dec/22 9:23 AM",
    Updated: "26/Jan/23 4:24 PM",
  },
  {
    Key: "PTCI-8368125",
    "Team Name": "WinWire - 4",
    Nickname: "WinWire - 4",
    Status: "Active",
    Manager: "Sunder Bharadwajan",
    Department: "Engineering",
    Domain: "Automation Services",
    "Sub Domain": "",
    "Team Size": "",
    "Team Email Address": "",
    "Public Slack Channel": "",
    "Pager Duty Escalation Service": "",
    SprintMailType: "",
    COPQFlag: "false",
    Labels: "Dev",
    Created: "22/Dec/22 9:23 AM",
    Updated: "26/Jan/23 4:24 PM",
  },
  {
    Key: "",
    "Team Name": null,
    Nickname: null,
    Status: null,
    Manager: null,
    Department: null,
    Domain: null,
    "Sub Domain": null,
    "Team Size": null,
    "Team Email Address": null,
    "Public Slack Channel": null,
    "Pager Duty Escalation Service": null,
    SprintMailType: null,
    COPQFlag: null,
    Labels: null,
    Created: null,
    Updated: null,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Onboard() {
  const [selectedMailingLists, setSelectedMailingLists] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [currentStep, setStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [company, setCompany] = useState("");
  const [tenant, setTenant] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState(_auth.getUserProfile());
  const [buttonText, setButtonText] = useState("Let's Go!");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [teams, setTeams] = useState([]);
  const history = useHistory();

  // useEffect(() => {
  //   const profileData = _auth.getUserProfile();
  //   console.log("ONBOARD profile", profileData);
  //   if (profileData) {
  //     setProfile(profileData);
  //     setTenant(profileData.tenantName);
  //     console.log(
  //       "ONBOARD tenant profile set useEffect",
  //       profileData.tenantName
  //     );
  //   }
  // }, []);
  useEffect(() => {
    (async () => {
      console.log("ONBOARD teams BEFORE");
      const teams = await _teams.getTeams();
      console.log("ONBOARD teams", teams);
      if (teams) {
        setTeams(teams);
      }
    })();
  }, [profile]);

  useEffect(() => {
    console.log("ONBOARD tenant change", tenant);
  }, [tenant]);

  const handleSelect = async (val) => {
    console.log("ONBOARD handleSelect", val);

    let tenantName = val["Team Name"];
    let key = val["Key"];
    console.log("ONBOARD tenantName", tenantName);

    setLoader(true);
    setButtonText("Creating Team...");
    let updatedTenant = await _user.renameTenant(tenantName, key);
    console.log("ONBOARD updatedTenant", updatedTenant);
    if (updatedTenant) {
      console.info("ONBOARD updatedTenant succeed", updatedTenant);

      //set tenant name in profile
      let p = profile;
      console.log("ONBOARD profile", p);
      let tenantId = updatedTenant.data.data._id;
      p.tenantName = tenantName;
      console.log("ONBOARD TenantId", tenantId, p);
      //find tenant in profile and update
      //tenants[0].tenantId._id = tenantId;

      let tenantIndex = profile.tenants.findIndex(
        (t) => t.tenantId._id === tenantId
      );
      console.log("ONBOARD tenantIndex", tenantIndex);

      profile.tenants[tenantIndex].tenantId.tenantTitle = val["Team Name"];

      profile.tenantInfo.tenantTitle = val["Team Name"];

      console.log("ONBOARD SETTING UPDATE PROFILE ...", profile);
      _auth.setUserProfile(profile);
      setProfile(profile);
      setTimeout(() => {
        setLoader(false);
        setButtonText("Done");
      }, 1000);
    } else {
      setButtonText("Close");
      setLoader(false);
      setError(true);
      setErrorMessage(
        "An error occurred while creating your team. You can still work, but you'll need to create a team later."
      );
      console.info("ONBOARD FAILED TO UPDATE TENANT");
    }
  };

  async function handleCheckboxClick(clickType) {
    if (clickType === "dontshowagain") {
      setChecked(!checked);
    }
    if (clickType === "onboardFinished") {
      if (checked) {
        let ans = await _onboarding_options.setDontShowAgain(!checked);
        console.log(ans, "ansss");
        if (ans) {
          return history.push({
            pathname: "/dashboard",
          });
        }
      } else {
        onFinish();
      }
    }
  }

  const onFinish = async () => {
    setLoader(true);
    return history.push({
      pathname: "/projects",
    });

    // if (checked) {
    //   let ans = await _onboarding_options.setDontShowAgain(!checked);
    //   console.log(ans, "ansss");
    //   if (ans) {
    //     return history.push({
    //       pathname: "/home",
    //     });
    //   }
    // } else {
    //   let data = {
    //     industry: selectedMailingLists,
    //     companySize: selectedSize,
    //     location: selectedLocation,
    //     role: selectedRole,
    //     department: selectedDepartment,
    //   };

    //   let results = await _onboarding_options.setUserFinishedOnboarding(
    //     data,
    //     history
    //   );
    // }
  };

  return (
    <div className="flex h-screen bg-white">
      {/* Content */}
      <div className="w-full">
        <div className="min-h-screen  bg-white flex flex-col after:flex-1">
          {/*New Progress Bar*/}
          <div className="md:flex h-48 flex-col after:flex-1 hidden">
            {/* <ArrowSteps
              setStep={setStep}
              steps={steps}
              currentStep={currentStep}
            /> */}
          </div>
          {/*Each step's component */}
          {/* <Fade right> */}
          <div className="px-4 py-8">
            {/* This div controls the width of the main content */}

            <div
              id="options_container"
              // style={{
              //   padding: "25px",
              //   minHeight: "60vh",
              //   maxHeight: "60vh",
              // }}
              className={classNames(
                currentStep == 7
                  ? "w-4/12 mx-auto" // null //"px-6 sm:px-6 lg:px-6 py-8 w-full"
                  : "w-4/12 mx-auto",
                "max-h-min"
              )}
            >
              {/* <div className="max-w-md mx-auto"> */}
              {/* <div> */}
              {/*Onboard step 1 - Introduction */}
              {currentStep == 1 && (
                <Fade right>
                  <div className="flex flex-col w-full after:flex-1">
                    <div className="overflow-y-none">
                      <LandingIntro />{" "}
                    </div>
                    <div className="sm:flex sm:justify-between sm:items-center">
                      <div className="mb-4 sm:mb-0">
                        <div className="text-lg  text-slate-800 font-bold"></div>
                      </div>
                    </div>
                    {/* <div className=" items-center">
                    <p className="mt-4  ">
                      For consistency, select your team from the list below. We
                      will populate the details for you.
                    </p>
                  </div> */}
                    {/* <div className="mt-6 mb-6">
                      <MultiSelectListBox
                        key={1}
                        options={teams}
                        fieldType="single"
                        valueField="Key"
                        displayField="Team Name"
                        label="Team Names"
                        name="teamlist"
                        impedimentId={null}
                        // handleSelect={null}
                        handleDeselect={null}
                        handleSelect={handleSelect}
                        value={tenant}
                      />
                      
                    </div> */}
                    <div class="flex justify-end items-right w-full ">
                      <Button
                        color="secondary"
                        fullWidth
                        shadow
                        size="lg"
                        // disabled={
                        //   buttonText !== "Done" && !error ? true : false
                        // }
                        // className="btn mt-6 w-36 rounded-md
                        // bg-white text-secondary-600
                        // border border-secondary-500
                        // shadow-md shadow-inner shadow-amber-600
                        // border-1.4
                        // hover:shadow-inner  hover:shadow-secondary-600  hover:text-secondary-600
                        // mx-2 my-2 shrink-0 "
                        exact
                        handleClick={onFinish}
                      >
                        {loader && (
                          <Skeleton
                            type="spinner"
                            color="gray"
                            fill="purple"
                            forButton={true}
                          />
                        )}{" "}
                        {buttonText}
                      </Button>
                    </div>
                    {error && (
                      <div className="mt-4">
                        <p className="text-red-500 text-sm">{errorMessage}</p>
                      </div>
                    )}
                  </div>
                </Fade>
              )}
              {/*Onboard step 2 - Your data */}
              {currentStep == 2 && (
                <Fade right>
                  <div className="sm:flex sm:justify-between sm:items-center mb-8">
                    <div className="mb-4 sm:mb-0">
                      <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        Let's help you get started.
                      </h1>
                    </div>
                  </div>
                  <div className=" items-center">
                    <p className="mt-4  ">
                      At some point we will use this onboarding wizard to
                      capture information about the user, let the build their
                      profile, or to provide information to the user about the
                      app. For now, I just have a placeholder.
                    </p>
                  </div>
                  <div className="mt-20">
                    {/* <Input
                      name="tenant"
                      placeholder="Team Name"
                      onChange={(e) => {
                        onChangeValue(e);
                      }}
                      value={tenant}
                      defaultValue={profile.tenantName}
                      className="mt-4"
                    /> */}
                  </div>
                </Fade>
              )}

              {/*Onboard step 3 - Industry */}
              {currentStep == 3 && (
                <Fade right>
                  <SingleOptions
                    title={"Role"}
                    options={roles}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedRole}
                    onSelectOption={(ans) => {
                      setSelectedRole(ans);
                      console.log(selectedRole, "role");
                    }}
                  />
                </Fade>
              )}
              {/*Onboard step 3 - Department */}
              {currentStep == 4 && (
                <Fade right>
                  <SingleOptions
                    title={"Department"}
                    options={departments}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedDepartment}
                    onSelectOption={(ans) => setSelectedDepartment(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 4 - Industry */}
              {currentStep == -5 && (
                <Fade right>
                  <SingleOptions
                    title={"Industry"}
                    options={mailingLists}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedMailingLists}
                    onSelectOption={(ans) => setSelectedMailingLists(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 5 - Company Size */}
              {currentStep == -5 && (
                <Fade right>
                  <SingleOptions
                    title={"Company Size"}
                    options={companySize}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedSize}
                    onSelectOption={(ans) => setSelectedSize(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 6 - Location */}
              {currentStep == -6 && (
                <Fade right>
                  <SingleOptions
                    title={"Location"}
                    options={companyLocation}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedLocation}
                    onSelectOption={(ans) => setSelectedLocation(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 7 - Resources */}
              {currentStep == -8 && (
                <Fade right>
                  <StackedList
                    title={"Other actions"}
                    options={options}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    onSelectOption={(ans) => setSelectedLocation(ans)}
                  />
                </Fade>
              )}

              {/* Checkbox - Don't show again */}
              {/* {currentStep == 1 && (
                <div className="mr-1">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      onClick={() => {
                        handleCheckboxClick("dontshowagain");
                      }}
                      // onClick={_onboarding_options.setDontShowAgain(this)}
                      className="form-checkbox"
                    />
                    <span className="text-sm ml-2">
                      Don't show this wizard again.
                    </span>
                  </label>
                </div>
              )} */}
            </div>

            {/* Buttons */}
            <div id="buttons" className="w-4/12 mx-auto justify-end">
              <div
                className="mt-20 flex-none md:flex  
               items-center  gap-4 "
              >
                {/* {!loader && (
                  <button
                    className="btn bg-primary-200 hover:bg-primary-200 text-white mx-2 my-2 shrink-0  w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                    onClick={() => {
                      if (!loader) {
                        history.push("/home");
                      }
                    }}
                  >
                    Finish later
                  </button>
                )} */}

                {currentStep > 1 && !loader && (
                  <button
                    //flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400
                    className="btn bg-primary-500 hover:bg-primary-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                    // to="/onboarding-02"
                    onClick={() =>
                      currentStep > 1 ? setStep(currentStep - 1) : null
                    }
                  >
                    &lt;- Previous Step
                  </button>
                )}
                {!checked ? (
                  <>
                    {currentStep < steps.length && currentStep > 1 ? (
                      <button
                        className="btn bg-lime-500 hover:bg-lime-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                        // to="/onboarding-02"
                        onClick={() => {
                          setStep(currentStep + 1);
                        }}
                      >
                        Step {currentStep + 1} -&gt;
                      </button>
                    ) : (
                      <>
                        {/* {loader ? ( */}
                        <div class="flex justify-center items-center">
                          {/* <img
                              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                              src={Spinner}
                              width="32"
                              height="32"
                              alt="loading.."
                            /> */}
                        </div>

                        {/* <button
                          className="btn bg-lime-500 hover:bg-lime-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                          exact
                          onClick={onFinish}
                        >
                          {loader && (
                            <Skeleton
                              type="spinner"
                              color="gray"
                              fill="purple"
                              forButton={true}
                            />
                          )}{" "}
                          Finish -&gt;
                        </button> */}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {
                      loader ? (
                        <div class="flex justify-center items-center">
                          {/* <img
                          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                          src={Spinner}
                          width="32"
                          height="32"
                          alt="loading.."
                        /> */}
                          <Skeleton type="spinner" color="gray" fill="purple" />
                        </div>
                      ) : null
                      // <button
                      //   className="btn bg-lime-500 hover:bg-lime-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                      //   exact
                      //   onClick={onFinish}
                      // >
                      //   <Skeleton
                      //     type="spinner"
                      //     color="gray"
                      //     fill="purple"
                      //     forButton={true}
                      //   />{" "}
                      //   Finish -&gt;
                      // </button>
                    }
                  </>
                )}
              </div>
            </div>
          </div>
          {/* </Fade> */}
        </div>
      </div>
    </div>
  );
}

export default Onboard;
