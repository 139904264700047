import React from "react";
import { ReactFormGenerator, ElementStore } from "react-form-builder2";
import { Button } from "../components/PaginationButtons";
import { templates } from "../modules/_templates";

export default class TemplateBuilderToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
    };

    this._onUpdate = this._onChange.bind(this);
  }

  componentDidMount() {
    ElementStore.subscribe((state) => this._onUpdate(state.data));
  }

  showPreview() {
    this.setState({
      previewVisible: true,
    });
  }

  showShortPreview() {
    this.setState({
      shortPreviewVisible: true,
    });
  }

  showRoPreview() {
    this.setState({
      roPreviewVisible: true,
    });
  }

  closePreview() {
    this.setState({
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
    });
  }

  _onChange(data) {
    this.setState({
      data,
    });
  }
  async onSubmit(data) {
    // const { postUrl } = this.props;
    console.log("onSubmit", data);
    // Place code to post json data to server here
    data.forEach((item) => {
      const custom_name = item.label ? item.label : item.name;
      //update data.item with new custom_name
      item.custom_name = custom_name;
      // if (custom_name) {
      //   data.push(item_data);
      // }
    });
    const saved = templates.saveFormTemplate(data);
    if (saved) {
      console.log("Saved", data);
      alert("Saved", data);
    }
    return false;
  }

  render() {
    let modalClass = "modal";
    if (this.state.previewVisible) {
      modalClass += " show d-block";
    }

    let shortModalClass = "modal short-modal";
    if (this.state.shortPreviewVisible) {
      shortModalClass += " show d-block";
    }

    let roModalClass = "modal ro-modal";
    if (this.state.roPreviewVisible) {
      roModalClass += " show d-block";
    }

    return (
      <div className="clearfix">
        <div className="bg-indigo-500  text-sm shadow-sm sticky top-0 mb-2 px-2 py-2 w-screen max-w-full ">
          <div className="sm:flex sm:justify-between sm:items-center">
            {/* Left Action */}

            <div className="flex flex-wrap justify-center text-white font-md sm:justify-start sm:mb-0 -space-x-3 -ml-px"></div>

            {/* // Right: Actions */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-secondary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                //   onClick={this.onSubmit.bind(this)}
                onClick={() => {
                  this.onSubmit(this.state.data);
                }}
              >
                Save Template
              </button>
              <button
                className="btn btn-primary float-right"
                style={{ marginRight: "10px" }}
                onClick={this.showPreview.bind(this)}
              >
                Preview Form
              </button>
              <button
                className="bg-white text-secondary-600  inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium  shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                style={{ marginRight: "10px" }}
                onClick={this.showPreview.bind(this)}
                // onClick={() => this.showPreview()}
              >
                Preview Form
              </button>
              {/* <Button
                color={"white"}
                audit={false}
                handleClick={null}
                className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
              >
                Discard Changes
              </Button>
              <Button
                color={"lime"}
                audit={false}
                handleClick={null}
                className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
              >
                Save
              </Button> */}
            </div>
          </div>
        </div>
        {this.state.previewVisible && (
          <div className={modalClass}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  <ReactFormGenerator
                    download_path=""
                    back_action="/"
                    back_name="Back"
                    answer_data={{}}
                    action_name="Save"
                    form_action="/"
                    form_method="POST"
                    onSubmit={this.submit}
                    variables={this.props.variables}
                    data={this.state.data}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={this.closePreview.bind(this)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.roPreviewVisible && (
          <div className={roModalClass}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  <ReactFormGenerator
                    download_path=""
                    back_action="/"
                    back_name="Back"
                    answer_data={{}}
                    action_name="Save"
                    form_action="/"
                    form_method="POST"
                    read_only={true}
                    variables={this.props.variables}
                    hide_actions={true}
                    data={this.state.data}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={this.closePreview.bind(this)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.shortPreviewVisible && (
          <div className={shortModalClass}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  <ReactFormGenerator
                    download_path=""
                    back_action=""
                    answer_data={{}}
                    form_action="/"
                    form_method="POST"
                    data={this.state.data}
                    display_short={true}
                    variables={this.props.variables}
                    hide_actions={false}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={this.closePreview.bind(this)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
