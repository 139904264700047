/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect } from "react";
import { useRef } from "react";
import React, { useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import Fade from "react-reveal/Fade";

import Logo from "../images/dsulogo.jpg";
import HeroImage from "../images/hero-cover1.jpg";
import cmapScreenshot1 from "../images/screenshot-breachlink-templates.png";
import cmapScreenshot2 from "../images/Screenshot-Compare.png";
import cmapScreenshot3 from "../images/screenshot-breachlink-comments.png";
import cmapScreenshot4 from "../images/screenshot-breachlink-marketplace.png";

//vendor logos
import epiq from "../images/logos/vendors/epiq.png";
import kroll from "../images/logos/vendors/kroll.png";
import integreon from "../images/logos/vendors/newIntegreon.png";
import consilio from "../images/logos/vendors/consilio.png";

import { ChevronDownIcon } from "@heroicons/react/solid";
import { Hero } from "../parts/Hero";
import { FormBanner } from "../parts/FormBanner";
import HubsForm from "../components/Hubsform";
import { SmallBanner } from "../parts/SmallBanner";
import { Header } from "../parts/Header";
import { ThreePartFeature } from "../parts/ThreePartFeature";
import FeatureGrid from "../parts/FeatureGrid";
import CenterBrandedCTA from "../parts/CenterBrandedCTA";
import TeamSmallPictures from "../parts/TeamSmallPictures";
import StatsLarge from "../parts/StatsLargeDark";
import FeatureList from "../parts/FeatureList";
import CTASplitWithImage from "../parts/CTASplitWithImage";
import SinglePriceWithDetails from "../parts/SinglePriceWithDetails";
import PartnerForm from "../parts/FormBannerPartner";
import ModalBasic from "../components/ModalBasic";
import NotificationBanner from "../components/NotificationBanner";
import PricingModule from "../parts/PricingModule";
import { _pricing } from "../modules/pricing";

import ThreeColumnOnDark from "../parts/ThreeColumnOnDark";
import LogoCloudWithCTA from "../parts/LogoCloudWithCTA";
import BenefitsWithBackground from "../parts/BenefitsWithBackground";
import SecondaryFeatures from "../parts/SecondaryFeatures";
import TestimonialsGrid2 from "../parts/TestimonialsGrid2";
import Team from "../parts/Team";
import SectionDivider from "../parts/SectionDivider";
import DevNote from "../components/DevNote";
import { _auth } from "../modules/_auth";
import TestimonialBanner from "../parts/TestimonialBanner";

// import { exec } from "child_process";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Home() {
  //set profile role to "user"
  _auth.setUserProfile({ role: "user" });
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const pricing = _pricing.getPricing();
  const setModalOpen = () => {
    setShowModal(false);
  };

  const handleBannerClose = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    setOpen(false);
  };
  // const hubsForm = useRef();
  const executeScroll = (e) => {
    // alert("scrolling to " + e);
    console.log("scrolling to ", e);
    const element = document.getElementById(e);
    element.scrollIntoView({
      alignToTop: true,
      behavior: "smooth",
      // block: "nearest",
      inline: "start",
    });
  };

  return (
    <div className="bg-white">
      {open && (
        <NotificationBanner
          className={"sticky top-10 z-100"}
          setOpen={(e) => handleBannerClose(e)}
          showClose={true}
          text={"Ask us about our services!"}
        />
      )}
      <main className="flex flex-col">
        <div id="top" className="mb-0 p-0">
          <Hero
            // title="Empowering Legal with Simplicity "
            title="Ready to respond when you need a hand"
            subtitle="Data breach resolution for small businesses, simplified.  "
            // subtitle="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly."
            image={HeroImage}
            description="Concerned about a data breach? A specialized attorney, and a team of world-class resolution providers, are just a phone call away."
            buttons=""
            executeScroll={executeScroll}
            setShowModal={setShowModal}
          />
        </div>
        <div id="overview" className="mt-0 p-0">
          <Fade id="breachlinkForEveryone">
            <ThreeColumnOnDark
              title={"How ReadyResponse can help you"}
              description={
                "ReadyResponse is a one-stop solution for data breach incidents. With virtually all small businesses holding sensitive personal information and nearly 50% experiencing breaches in the past year, small businesses are a favorite target for hackers. Small companies are especially vulnerable because many can’t afford or don’t qualify for cyber insurance, or will get dropped after a claim. ReadyResponse offers an easy, cost-effective way to address a cyber incident."
              }
              variant={"light"}
              /*features={[
                {
                  name: "Law Firms",
                  description:
                    "Breachlink saves you time and money. Compare bids, track statuses, and perform due diligence with ease.",
                  href: "#",
                  icon: "hugeicons:justice-scale-02",
                },
                {
                  name: "Insurance Carriers",
                  description:
                    "Breachlink helps insurers oversee the bid process with greater efficiency and transparency. ",
                  href: "#",
                  icon: "ri:shield-user-fill",
                },
                {
                  name: "Corporations",
                  description:
                    "Use Breachlink like a law firm to streamline your processes or like an insurer to gain real-time insights.",
                  href: "#",
                  icon: "ri:building-2-fill",
                },
              ]}*/
            />
          </Fade>{" "}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 w-full px-40 mb-8">
          <div id="picture" className="col-span-4 w-full hidden lg:block">
            <img src={require("../images/RR Process.png")} alt="RR Process" className="w-full"/>
          </div>

          <div className="col-span-4 lg:col-span-1 flex flex-col items-center justify-start">
            <div id="step-1-header" className="text-2xl font-bold">
              Step One
            </div>
            <div id="descr-1" className="flex flex-col items-start justify-start text-center">
              Gather relevant details about the incident.
            </div>
          </div>

          <div className="col-span-4 lg:col-span-1 flex flex-col items-center justify-start">
            <div id="step-2-header" className="text-2xl font-bold">
              Step Two
            </div>
            <div id="descr-2" className="flex flex-col items-start justify-start text-center">
              Pick up the phone and use one of your free triage calls.
            </div>
          </div>

          <div className="col-span-4 lg:col-span-1 flex flex-col items-center justify-start">
            <div id="step-3-header" className="text-2xl font-bold">
              Step Three
            </div>
            <div id="descr-3" className="flex flex-col items-start justify-start text-center">
              Get priority access to a specialized data breach attorney.
            </div>
          </div>

          <div className="col-span-4 lg:col-span-1 flex flex-col items-center justify-start">
            <div id="step-4-header" className="text-2xl font-bold">
              Step Four
            </div>
            <div id="descr-4" className="flex flex-col items-start justify-start text-center">
              If needed, best-in-class providers will work at discounted rates to address outstanding issues. 
            </div>
          </div>
        </div>
        {/* 
        <Fade id="small-banner-create-in-seconds">
          <div className={`  bg-slate-800 py-12`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-7xl text-center">
                <p
                  className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
                >
                  <div className=" text-opacity-80 ">
                    Stop digging through emails and making spreadsheets.
                  </div>

                  <div className="text-brandGreen-500">
                    Let Breachlink do the work for you.
                  </div>
                </p>
              </div>
            </div>
          </div>
          <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> 
        </Fade>
        */}

        {/*<Fade id="testimonial-banner">
          <TestimonialBanner />
        </Fade>*/}
        {/* <Fade id="logoCloud">
          <div>
            <DevNote>
              We need to find some law firms who have used us to let us put
              their logos up. Ideally without us making a concession.
            </DevNote>
            <LogoCloudWithCTA
              title="Trusted by the world’s most innovative legal teams"
              showCTA
              ctaClick={() => {
                // alert("clicked");
                executeScroll("testimonials");
              }}
              ctaTitle={"We're used by over 50 law firms"}
              ctaText={"See our customer stories"}
              logos={[
                {
                  src: epiq,
                  alt: "Epiq",
                  width: 158,
                  height: 48,
                },
                {
                  src: kroll,
                  alt: "Kroll",
                  width: 158,
                  height: 48,
                },
                {
                  src: integreon,
                  alt: "Integreon",
                  // width: 158,
                  // height: 48,
                },
                {
                  src: consilio,
                  alt: "Consilio",
                  width: 158,
                  height: 48,
                },
                // {
                //   src: "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
                //   alt: "Statamic",
                //   width: 158,
                //   height: 48,
                // },
              ]}
            />
          </div>
        </Fade> */}

        {/* 
        <div id="solutions"> </div>
        <Fade id="section-law-benefits">
          <div id="lawFirms">
            {" "}
            <BenefitsWithBackground
              id={"lawFirms"}
              title={"Law Firms"}
              header={
                "Maximize Efficiency and Quality in Your Vendor Management"
              }
              orientation="normal"
              description={
                "Breachlink saves you time and money. Compare bids, track statuses, and perform due diligence with ease."
              }
              data={[
                {
                  name: "Optimize Costs",
                  description:
                    "Encourage competitive bids to achieve the best value for your investments.",
                },
                {
                  name: "Improve Quality",
                  description:
                    "Access a marketplace of vetted, high-quality vendors.",
                },
                {
                  name: "Easy Comparisons",
                  description:
                    "Side-by-side bid comparisons to make informed decisions.",
                },
                {
                  name: "Collaborate",
                  description: "Collaborate with your team in real-time.",
                },
                {
                  name: "Tracking and Reporting",
                  description:
                    "Dashboards and reports for comprehensive bid tracking and analysis.",
                },
                {
                  name: "Vendor Flexibility",
                  description:
                    "Enjoy the freedom to choose the best vendor for each project, ensuring you always have the right partner.",
                },
              ]}
              layout="grid"
              // orientation="left"
              backgroundColor={"bg-white"}
              gradient={false}
            />
          </div>
        </Fade>
        */}

        {/* <Fade id="section-corp-benefits">
          <div id="corporate">
            {" "}
            <BenefitsWithBackground
              id={"corp-BidManagement"}
              variant="light"
              title={"Corporate Legal"}
              header={
                "Optimize Your Legal Spend and Vendor Management Processes."
              }
              orientation="reverse"
              description={
                "Whether insured or not, corporations can benefit from Breachlink's robust platform for managing legal and vendor relationships. Use Breachlink like a law firm to streamline your processes or like an insurer to gain real-time insights."
              }
              data={[
                {
                  name: "Efficiency",
                  description:
                    "Manage bids, track statuses, and perform due diligence with an all-in-one platform.",
                },
                {
                  name: "Quality Assurance",
                  description:
                    "Access a network of vetted vendors to ensure high standards.",
                },
                {
                  name: "Cost Management",
                  description:
                    "Optimize your spending by encouraging competitive bidding.",
                },
                {
                  name: "Real-Time Access",
                  description:
                    "Utilize Breachlink Connect to view bids in real-time, saving time and ensuring better decision-making.",
                },
                {
                  name: "Transparency",
                  description:
                    "Maintain clear visibility into the bid process, enhancing compliance and quality control.",
                },
                {
                  name: "Collaboration",
                  description:
                    "Work seamlessly with your legal and procurement teams to select the best vendors.",
                },
              ]}
              backgroundColor={"bg-gray"}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
            />
          </div>
        </Fade> */}
        <Fade id="section-insurance-benefits">
          <div id="insurance">
            {" "}
            <BenefitsWithBackground
              id={"insurance-benefits"}
              title={""}
              header={"Powerful features that put you in control."}
              orientation="normal"
              description={
                "Explore the unique features of ReadyResponse that make it the ideal solution for small businesses."
              }
              data={[
                {
                  name: "Triage Call",
                  description:
                    "Up to 4 triage calls/year to an experienced data breach attorney for assessing potential breaches.",
                },
                {
                  name: "Cost Savings",
                  description:
                    "Save up to 70% on breach services: forensics, ransomware negotiations, data mining, reviews, mailing & more.",
                },
                {
                  name: "Incident response plan",
                  description:
                    "An incident response plan with simple, easy to follow instructions in the event of a breach.",
                },
                {
                  name: "Member Only Offers",
                  description:
                    "Save on security software, training, and more with discounts only available to ReadyResponse members.",
                },
                {
                  name: "Risk Assessment",
                  description:
                    "A preparedness assessment to help you identify current vulnerabilities.",
                },
                {
                  name: "Educational Resources",
                  description:
                    "Articles, trends, whitepapers, webinars, and more.",
                },
              ]}
              layout={"grid"}
              backgroundColor={"bg-gray"}
              gradient={false}
            />
          </div>
        </Fade>

        {/* FEATURE SECTION */}
        {/* BEGIN -- ORAN COMMENTED OUT */}
        {/* <div id="features"> </div>
        <div id="all-features-div" className="py-12">
          <Fade id="section-features">
            <div id="" className="">
              {" "}
              <SectionDivider
                title={"Powerful features that put you in control"}
                description={
                  "Explore the unique features of ReadyResponse that make it the ideal solution for small businesses."
                }
                variant={"dark"}
              />
              <BenefitsWithBackground
                id={"feature-1"}
                variant="dark"
                title={"TRIAGE CALL"}
                header={
                  "4 triage calls/year to an experienced data breach attorney for assessing potential breaches"
                }
                orientation="reverse"
                description={
                  "Priority access to data breach attorneys who’ll help your business meet all the legal obligations triggered by an incident."
                }
                data={[]}
                backgroundColor={"bg-gray"}
                layout="grid"
                gradient={false}
                backgroundImage={{
                  src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                  alt: "People working on laptops",
                }}
                featureImage={cmapScreenshot1}
              />
            </div>
          </Fade>
          <Fade id="section-features-2">
            <BenefitsWithBackground
              id={"feature-2"}
              variant="dark"
              title={"SAVINGS"}
              header={"Up to 70% savings on breach-related services"}
              orientation="normal"
              description={
                "Deeply discounted rates for additional legal and breach related services like forensics, ransomware negotiations, data mining, document review, mailing, call center, and credit monitoring."
              }
              data={[]}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot2}
              // bgColor={"gray-100"}
            />
          </Fade>
          <Fade id="section-feature-3">
            <BenefitsWithBackground
              id={"corp-BidManagement"}
              variant="dark"
              title={"INCIDENT RESPONSE"}
              header={"Incident response plan"}
              orientation="reverse"
              description={
                "Each client receives an incident response plan with simple, easy to follow instructions in the event of a breach."
              }
              data={[]}
              bg-color={"bg-gray-200"}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot3}
            />
          </Fade>
          <Fade id="section-features-0">
            <BenefitsWithBackground
              id={"feature-0-realtime-bid-monitoring"}
              variant="dark"
              title={"RISK ASSESSMENT"}
              header={"DIY security risk assessment"}
              orientation="normal"
              description={
                "Worried about your security? We offer breach preparedness assessments to help you identify current vulnerabilities."
              }
              data={[]}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot2}
              // bgColor={"gray-100"}
            />
          </Fade>
          <Fade id="section-features-00">
            <BenefitsWithBackground
              id={"feature-0-realtime-bid-monitoring"}
              variant="dark"
              title={"EXCLUSIVE OFFERS"}
              header={"Educational resources and access to member-only offers"}
              orientation="reverse"
              description={
                "ReadyResponse members get access to exclusive offers from our partners. Save on security software, training, and more with discounts only available to ReadyResponse members."
              }
              data={[]}
              layout="grid"
              gradient={false}
              backgroundImage={{
                src: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80",
                alt: "People working on laptops",
              }}
              featureImage={cmapScreenshot3}
              // bgColor={"gray-100"}
            />
          </Fade>
        </div> */}
        {/* END  -- ORAN COMMENTED OUT */}
        {/* 
        <div>
          <Fade id="small-banner-create-in-seconds">
            <div className={`  bg-slate-800 py-12`}>
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-7xl text-center">
                  <p
                    className={`mt-2 text-4xl  font-semibold tracking-tight text-white`}
                  >
                    <div className=" text-opacity-80 ">
                      {" "}
                      Create bid requests in seconds. Get bids in minutes.{" "}
                    </div>

                    <div className="text-brandGreen-500">
                      Compare them effortlessly.
                    </div>
                  </p>
                </div>
              </div>
            </div>
            */}
        {/* <SmallBanner description="Create bid requests in seconds. Get bids in minutes. Compare them effortlessly." /> */}
        {/* 
         </Fade>
        </div>
        */}
        {/* 
        <div className="">
          <Fade id="section-marketplace-list">
            <div className="">
            */}
        {/* <DevNote>
                Need to update our T's & C's to say we can use their logo and
                give them the ability to opt out and not receive bid requests.
              </DevNote>{" "} */}
        {/* 
              <SectionDivider
                title={"A marketplace beyond Data Breach and eDiscovery."}
                description={
                  "The Breachlink marketplace is designed to connect you with the best service providers in the industry. From preparedness and training to litigation support, our marketplace offers a wide range of categories to meet your needs."
                }
                variant={"light"}
                // bgColor={"gray-100"}
              />
              <FeatureList
                id={"marketplace"}
                title={""}
                header={""} //A marketplace beyond Data Breach and eDiscovery.
                description={""} //Breachlink started with post-data breach service providers.  But we've expanded to include breach preparedness as well as litigation support. Check out just a few of the categories featured in our marketplace:
                features={[
                  {
                    name: "Preparedness & Training",
                    description: "",
                  },
                  {
                    name: "Managed Detection & Response",
                    description: "",
                  },
                  {
                    name: "Forensics & Ransomware",
                    description: "",
                  },
                  {
                    name: "Data Mining & Document Review",
                    description: "",
                  },
                  {
                    name: "Notification & Identity Services",
                    description: "",
                  },
                  {
                    name: "eDiscovery & Litigation Support",
                    description: "",
                  },
                ]}
                variant={"light"}
                // bgColor={"gray-100"}
              />
              */}
        {/* <div className="bg-gray-100 px-24 pb-24">
                <img
                  src={cmapScreenshot4}
                  alt="hero"
                  className="shadow-xl rounded-3xl"
                />
              </div> */}
        {/* 
            </div>
          </Fade>
        </div>
        */}
        {/* 
        <Fade id="logoCloud">
          <div className="relative">
            <LogoCloudWithCTA
              title="
                Here are a few of our featured vendors."
              showCTA={false}
              ctaTitle={
                "Our marketplace currently has over 50 world-class service providers and gorowing every day."
              }
              ctaText={"Check out the full marketplace"}
              logos={[
                {
                  src: epiq,
                  alt: "Epiq",
                  width: 158,
                  height: 48,
                },
                {
                  src: kroll,
                  alt: "Kroll",
                  width: 158,
                  height: 48,
                },
                {
                  src: integreon,
                  alt: "Integreon",
                  // width: 158,
                  // height: 48,
                },
                {
                  src: consilio,
                  alt: "Consilio",
                  width: 158,
                  height: 48,
                },
                // {
                //   src: "https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg",
                //   alt: "Statamic",
                //   width: 158,
                //   height: 48,
                // },
              ]}
              bgColor={"bg-gray-100"}
            />
          </div>
        </Fade>
        */}
        {/* CTA SECTION */}

        {/* 
        <Fade id="section-split-image-cta">
          <CTASplitWithImage
            title=""
            header="Curious about how Breachlink can help you?"
            description="Signing up is free and easy. Get started today."
            ctaText="Sign up for free"
            executeScroll={executeScroll}
          />
        </Fade>
        */}
        {/*<div id="services" className="">
          <Fade id="fade-services">
            <StatsLarge
              id={"services"}
              title={"Services"}
              header={"ReadyResponse offers services that simplify and expedite your data breach response."}
              orientation="reverse"
              variant={"light"}
              description={
                "From the instant you get breached, you can be assured that you have access to the right team and services to help you navigate the situation. Our services are designed to help you respond quickly and effectively to data breaches, ensuring that you can protect your business and your customers."
              }
              data={[
                {
                  stat: "Immediate Access to Data Breach Attorneys",
                  rest: "Get expert legal guidance when you need it most.",
                },
                {
                  stat: "Free Breach Assessments",
                  rest: "Determine the severity of your breach and the steps you need to take.",
                },
                {
                  stat: "Legal Guidance and Specialized Vendors",
                  rest: "Connect with the right team to help you respond to your breach.",
                },
                {
                  stat: "Full Incident Response Management",
                  rest: "Get help managing your breach, from breach discovery to regulatory compliance.",
                },
              ]}
              showCTA={false}
              ctaText={"Get started"}
              ctaClick={"#contact"}
              layout="grid"
              gradient={false}
              showBackground={false}
            />
          </Fade>
        </div>
        */}

        {/* <Fade id="section-testimonials">
          <div id="testimonials">
            <DevNote>
              We need to get some testimonials. Just need a few.{" "}
              <p>This is not the final design for testimonials.</p>
            </DevNote>
            <SectionDivider
              title={"What our customers are saying"}
              description={
                "We work with some amazing people. Here's what they have to say."
              }
              variant="light"
            />
            <TestimonialsGrid2 id="testimonials-component" />
          </div>
        </Fade> */}
        {/*
        <div id="vendors">
          <Fade id="section-service-providers">*/}
        {/* <DevNote>Content to be updated with latest </DevNote>{" "} */}
        {/* <SectionDivider
              title={"Become a vendor on Breachlink"}
              description={
                "Want to be among the very best in the world?  Get listed in our marketplace and start bidding on jobs"
              }
              variant={"dark"}
            /> */}
        {/*
            <StatsLarge
              id={"serviceProviders"}
              title={"Service Providers"}
              header={"Join Our Network of Trusted Service Providers"}
              description={
                "Are you a service provider looking to expand your reach and connect with more potential clients? Join Breachlink to showcase your value and grow your business."
              }
              variant={"dark"}
              showCTA={true}
              ctaText={"Join our network"}
              ctaClick={"https://app.breachlink.com/signup"}
              data={[
                {
                  id: 1,
                  stat: "Detailed bidding",
                  emphasis: "",
                  rest: "Break down your bids to show your value in user-friendly detail.",
                },
                {
                  id: 2,
                  stat: "Q&A",
                  emphasis: "",
                  rest: "Ask and answer questions to clarify your bid and win the job.",
                },
                {
                  id: 3,
                  stat: "Be discovered",
                  emphasis: "",
                  rest: "Get in front of the right people, at the right time, every time.",
                },
                {
                  id: 4,
                  stat: "Track your success",
                  emphasis: "",
                  rest: "See how you compare to the competition and improve.",
                },
              ]}
            />
          </Fade>
        </div>
        */}
        {/*
        <Fade id="section-team">
          <div id="team" className="h-full min-h-[800px]">
          */}
        {/* <DevNote>
              Will create team profiles. Need to decide who is listed. Link will
              go to new company page.
            </DevNote> */}
        {/*

            <SectionDivider
              title="Meet the Team Behind Breachlink"
              description="At Breachlink, our mission is to revolutionize the legal
              industry's approach to data breach response and eDiscovery. Meet
              the dedicated team driving innovation and excellence."
              variant="light"
            />
            <Team />
          </div>
        </Fade>
        */}

        {/*
        <Fade id="cta-ready-next-step">
          {" "}
          <CenterBrandedCTA
            text={"Ready to take the next step?"}
            buttonText={"Sign up for ReadyResponse today"}
            link={"https://app.breachlink.com/signup"}
            executeScroll={executeScroll}
            bottomText={""}
            variant="brand" //light, brand, dark
          />
        </Fade>*/}

        <Fade id="section-hubspot-section-1">
          <div id="contact" className=" lg:pt-0">
            <HubsForm id="hubsForm-rr" variant={"light"} />
          </div>
        </Fade>
      </main>
      <ModalBasic
        id="partner_modal"
        modalOpen={showModal}
        setModalOpen={setModalOpen}
        title="Get in touch!"
      >
        <PartnerForm id="partnerForm" />
      </ModalBasic>
      {/* ------------------------------------ --------- ----------------------------------*/}
      {/* ------------------------------------ GRAVEYARD ----------------------------------*/}
      {/* ------------------------------------ --------- ----------------------------------*/}
      {/* <SmallBanner
          description={
            "At Breachlink, we're transforming the data breach response and eDiscovery processes for law firms, insurance companies, corporations, and service providers. Our platform is designed to optimize costs, enhance quality, and save you precious time by streamlining the procurement process."
          }
        /> */}
      {/* <CenterBrandedCTA
          variant="light"
          text={"Convinced? We thought you would be."}
          buttonText={"Sign up for free"}
          link={"https://app.breachlink.com/signup"}
          executeScroll={executeScroll}
          bottomText={"If you're not, contact us and let us convince you!"}
        /> */}
      {/* <SmallBanner
          description={
            "Ready to make security assessments part of your offering? We've got you covered with reseller, whitelabel, and integration partner programs!"
          }
          partner={true}
          executeScroll={executeScroll}
          setShowModal={setShowModal}
        /> */}
      {/* <SinglePriceWithDetails /> */}
      {/* <HubsForm id="hubsForm" /> */}
      {/* <div id="solutions-lawFirms">
          <ThreePartFeature
            title={"Law Firms"}
            header={"Maximize Efficiency and Quality in Your Vendor Management"}
            description={
              "Breachlink streamlines your data breach response, saving you time and money. Compare bids, track statuses, and perform due diligence with ease."
            }
            features={[
              {
                name: "Optimize Costs",
                description:
                  "Encourage competitive bids to achieve the best value for your investments.",
              },
              {
                name: "Improve Quality",
                description:
                  "Access a marketplace of vetted, high-quality vendors.",
              },
              {
                name: "Easy Comparisons",
                description:
                  "Side-by-side bid comparisons to make informed decisions.",
              },
              {
                name: "Tracking and Reporting",
                description:
                  "Dashboards and reports for comprehensive bid tracking and analysis.",
              },
              {
                name: "Vendor Flexibility",
                description:
                  "Enjoy the freedom to choose the best vendor for each project, ensuring you always have the right partner.",
              },
            ]}
          />
        </div> */}
      {/* <Fade id="section-feature-carousel">
          {" "}
          <SecondaryFeatures
            showImages
            title="Notable Features"
            description="Explore some of our features by clicking the feature descriptions."
            images={[]}
          />
        </Fade> */}
      {/* <ModalBasic
        id="client_modal"
        modalOpen={showClientModal}
        setModalOpen={setClientModalOpen}
        title="Join today!"
      >
        <HubsForm id="hubsForm" />
      </ModalBasic> */}
    </div>
  );
}
