import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { _onboarding_options } from "../modules/_onboarding_options";

const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ShuffleArray(array, qtype) {
  if (qtype == "True/False") {
    array.sort(function (a, b) {
      return b - a;
    });
    return array;
  }

  if (qtype == "Yes/No") {
    return array;
  }

  if (qtype == "Multiple Choice") {
    return array;
    //removed shuffle for now
    // for (let i = array.length - 1; i > 0; i--) {
    //   const j = Math.floor(Math.random() * (i + 1));
    //   [array[i], array[j]] = [array[j], array[i]];
    // }
  }
}

function PutOptionAtEnd(array, text) {
  //if the option is found, move it to the end of the array
  if (array.includes(text)) {
    //remove all of the above from array
    //add it to the end of the array
    var index = array.indexOf(text);
    if (index > -1) {
      array.splice(index, 1);
    }
    array.push(text);
  }
  return array;
}

function YesNoQuestions(props) {
  var question = props.question;
  // console.info("THIS IS THE QUESTION", question);
  const questions = props.questions;
  // console.info("questions", questions);
  const totalQuestions = questions.length;
  const title = props.title;
  const qid = props.qid;
  let qtype; //

  if (question.type) {
    qtype = question.type;
  } else {
    qtype = question.answer_options
      ? typeof question.answer_options[0] === "string"
        ? "Multiple Choice" //multiple choice
        : "True/False" //true/false
      : "Yes/No"; //yes/no
  }

  console.info("QTYPE", qtype);

  let answerOpts = question.answer_options
    ? question.answer_options
    : props.answerOpts;

  console.info("ANSWER OPTS", answerOpts);

  ShuffleArray(answerOpts, qtype);
  PutOptionAtEnd(answerOpts, "All of the above.");
  PutOptionAtEnd(answerOpts, "All of the above");

  const setCount = props.setCount;
  const [selectedDepartment, setSelectedDepartment] = useState(departments[0]);

  const handleOnChange = (e) => {
    //check if e is a string
    // if (typeof e === "string") {
    //   e = e.toLowerCase();
    // }

    let best_answer = question.best_answer;
    // if (typeof best_answer === "string") {
    //   best_answer = best_answer.toLowerCase();
    // }

    //check if question has multiple best answers
    //this is for future support
    var isAnswerArray = Array.isArray(best_answer ? true : false);
    if (isAnswerArray) {
      console.info("Best Answer", best_answer);
      if (e in best_answer) {
        console.info("Correct Answer");
        setCount("yes");
        question.isCorrect = true;
      } else {
        console.info("Wrong Answer");
        setCount("no");
        question.isCorrect = false;
      }
    } else {
      //this is the most common question type
      // console.info("Best Answer", best_answer);
      if (e === best_answer) {
        console.info("Correct. Your Answer", e, "Best Answer", best_answer);
        setCount("yes");
        question.isCorrect = true;
      } else {
        console.info("Incorrect. Your Answer", e, "Best Answer", best_answer);
        setCount("no");
        question.isCorrect = false;
      }
    }

    // setCount(e);
    setSelectedDepartment(e);
    // console.info("THIS IS THE QUESTION", question);
    question.your_answer = e;

    // console.info("THIS IS THE UPDATED QUESTION", question);
    // props.setUpdatedQuestion(question); //pass answer to parent
    //Find index of specific object using findIndex method.

    props.UpdateHistoryObject(question); //pass answer to parent
    console.info("Updated History with question", qid);
    console.info("Updated history with question", question);
    console.info("Total Questions", totalQuestions);
    qid < totalQuestions && props.setCurrentQuestion(props.currentQuestion + 1);
    // : props.FinishAssessment(question);
    //save question object with "your answer"
    // console.info(e);
  };
  return (
    <div className=" grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-4">
      {answerOpts.map(
        (department, idx) => (
          console.log("ANS OPTS", department),
          (
            <RadioGroup
              key={department.id ? department.id : department}
              value={question.your_answer}
              onChange={handleOnChange}
            >
              <RadioGroup.Option
                key={department.id ? department.id : department}
                value={department.id ? department.id : department}
                className={({ checked, active }) =>
                  classNames(
                    checked ? "border-transparent" : "border-gray-300",
                    active ? "ring-2 ring-secondary-500" : "",

                    "items-center justify-center relative bg-white border rounded-lg  p-4 flex cursor-pointer focus:outline-none",
                    "hover:border-gray-300 hover:shadow-md hover:bg-gray-50 "
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <div className="flex-1 flex  items-center">
                      <div className="flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          // className="block text-sm font-medium text-slate-800"
                          className={`block text-sm font-medium text-slate-800 ${
                            !department.title &&
                            (department == true || department == false)
                              ? "capitalize"
                              : ""
                          }`}
                        >
                          {department.title
                            ? department.title
                            : department.toString()}
                        </RadioGroup.Label>
                      </div>
                    </div>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? "invisible" : "",
                        "h-5 w-5 text-secondary-600"
                      )}
                      aria-hidden="true"
                    />
                    <div
                      className={classNames(
                        active ? "border" : "border-2",
                        checked ? "border-secondary-500" : "border-transparent",
                        "absolute -inset-px rounded-lg pointer-events-none"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          )
        )
      )}
    </div>
    //   </div>
    // </div>
  );
}

export default YesNoQuestions;
