import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";

import ProfileSidebar from "../components/ProfileSidebar";
import Button from "../elements/Button";
import ProfileBody from "../components/ProfileBody";
import ImpedimentDetailsBody from "../components/ImpedimentDetailsBody";

function ImpedimentDetails(...props) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false);
  const [data, setData] = useState(location.state.data);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [list, setList] = useState(location.state.list);
  const history = useHistory();
  const fullList = location.state.list;
  console.log("fullList", list);

  useEffect(() => {
    console.log("useEffect", data);
  }, [data]);

  const handleBack = () => {
    console.log("handleBack");
    let path = "/impediments";
    return history.push({
      pathname: path,
      state: { list: fullList },
    });
  };

  const handleSetData = (data) => {
    console.log("Data Received from handleSelectEmployee", data);
    setData(data);
  };

  const handleSetList = (list) => {
    console.log("List Received from handleSelectEmployee", list);
    //turn list into array using list.decription

    setList(list);
  };

  //   console.info("handleSelectEmployee Employee Profile", data);
  return <ImpedimentDetailsBody data={data} handleBack={handleBack} />;
}

export default withRouter(ImpedimentDetails);
