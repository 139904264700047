const context = require.context(".", false, /.jpg$/);
// console.info("IMAGESLIST", context.keys());
const obj = {};
context.keys().forEach((key) => {
  const imageCode = key.substring(2, key.length - 4); // remove the file extension

  obj[imageCode] = context(key);
});
// console.info("IMAGESLIST", obj);

export default obj;
