import create from "zustand";
import { persist, devtools } from "zustand/middleware";

import createLoginSlice from "./createLoginSlice";
import createAssesmentSlice from "./createAssesmentSlice"

let persistStore = (set, get) => ({
  ...createLoginSlice(set, get),
  // ...createCompanySlice(set, get),
});
persistStore = devtools(persistStore);
persistStore = persist(persistStore, {
  name: "app-storage", // unique name
  getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
});

let nonPersistStore = (set, get) => ({
  ...createAssesmentSlice(set,get)
});
// nonPersistStore = devtools(nonPersistStore);

export const usePersistStore = create(persistStore);
export const useStore = create(nonPersistStore);
