import { hubsInterface } from "../modules/_hubs";
import React from "react";
export default function PartnerForm() {
  const [showThankYouMessage, setShowThankYouMessage] = React.useState(false);
  const [thankYouMessage, setThankYouMessage] = React.useState("");

  const handleSubmit = (event) => {
    let formId = "3a689bff-3436-4c45-ab42-0cad35da27c5"; // "6c5d99d0-d429-4344-bd4c-24a896cda433";
    event.preventDefault();

    console.log(event.target.email.value);

    const response = hubsInterface.submitForm(
      event.target.email.value,

      formId,
      "ContactUs",
      event.target.message.value,
      event.target.firstname.value,
      event.target.lastname.value,
      event.target.company.value
    );
    console.log("response", response);
    setShowThankYouMessage(true);
    setThankYouMessage(
      "Thank's for reaching out to us. We will be in touch soon!"
    );
  };
  return (
    <div className="bg-white">
      <div
        id="partner_form"
        className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
      >
        {/* <h2 className="py-3 inline text-3xl font-bold tracking-tight text-slate-800 sm:block sm:text-4xl sm:tracking-tight">
          Ready to make life easier?
        </h2> */}
        <p className="py-3 inline text-2xl font-bold tracking-tight text-indigo-600 sm:block sm:text-4xl sm:tracking-tight">
          Let us know what's on your mind!
        </p>
        <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-500">
          We'd love to hear from you. Please use the form below to reach out to
          us!
        </p>
        <p className="mt-3 max-w-3xl text-lg leading-6 text-indigo-500">
          {showThankYouMessage && thankYouMessage}
        </p>
        {!showThankYouMessage && (
          <form className="sm:flex" onSubmit={handleSubmit}>
            <div className="space-y-3 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:gap-y-6 ">
              <div className="w-full col-span-full md:col-span-2">
                <label htmlFor="email-address" className="sr-only">
                  First Name
                </label>
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  autoComplete="firstname"
                  required
                  className="w-full px-5 py-3 placeholder-gray-500 focus:ring-indigo-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                  placeholder="Enter your first name"
                />
              </div>
              <div className="w-full col-span-full md:col-span-2">
                <label htmlFor="email-address" className="sr-only">
                  Last Name
                </label>
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  autoComplete="lastname"
                  required
                  className="w-full px-5 py-3 placeholder-gray-500 focus:ring-indigo-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                  placeholder="Enter your last name"
                />
              </div>
              <div className="w-full col-span-full md:col-span-2">
                <label htmlFor="company" className="sr-only">
                  Company
                </label>
                <input
                  id="company"
                  name="company"
                  type="text"
                  autoComplete="company"
                  required
                  className="w-full px-5 py-3 placeholder-gray-500 focus:ring-indigo-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                  placeholder="What company do you work for? (optional)"
                />
              </div>
              <div className="w-full col-span-full md:col-span-2">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full px-5 py-3 placeholder-gray-500 focus:ring-indigo-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                  placeholder="Enter your email"
                />
              </div>

              <div className="w-full col-span-full md:col-span-4">
                <label htmlFor="email-address" className="sr-only">
                  Message
                </label>
                <textarea
                  //multi line

                  id="message"
                  name="message"
                  type="text"
                  // autoComplete="email"
                  required
                  className="sm:max-w-full w-full col-span-2 px-5 py-3 placeholder-gray-500 focus:ring-indigo-500 focus:ring focus:border-0 sm:max-w-xs border-solid border border-gray-300  rounded-md"
                  placeholder="Type your message here"
                />
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Get in touch
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
