/*
  To show a feature based on criteria, wrap the feature's button in this component.
    The component will check the feature's flag and return the button if the flag is true.

    Example:
        <Feature type="product" productId={productID} featureName="Settings">
            <button>Feature</button>
        </Feature>

    The type can be "flag", "test", or "subscription", and others will be added in the future.
    The "flag" type will check the feature's flag and return the button if the flag is true.
    The "test" type will check if the user is a  user and return the button if true.
    The "subscription" type will check if the user has a subscription to the product and return the button if true.

  */

/* Should we auto-show all features in Beta? */

import React from "react";
import { useState, useEffect, useContext } from "react";
import { features } from "../modules/_features";
import { _auth } from "../modules/_auth";
import { _stripe } from "../modules/_stripe";
import { utils } from "../modules/_utils";
import { Context } from "../layouts/store";

export function FeatureFlag(props) {
  const type = props.type ? props.type : "flag";
  const role = "user"; //props.role ? props.role : _auth.getUserProfile().role;
  // const productId = _auth.getUserProfile().stripeProducts;
  const { featureName } = props;
  // const [tier, setTier] = useState(null);
  // const tier = _auth.getUserProfile().tier; //needs to change to cache
  const { children } = props;
  const [isEnabled, setIsEnabled] = useState(false);
  const [cache, setCache] = useState();
  const state = useContext(Context);
  const [flags, setFlags] = useState();

  useEffect(() => {
    setFlags(state && state.flags ? state.flags : null);
  }, [state && state.flags && state.flags]);

  // useEffect(() => {
  //   // (async () => {
  //   console.log("Checking isEnabled", featureName);
  //   const isEnabled = await features.isEntitled1(featureName, flags);
  //   if (isEnabled) {
  //     setIsEnabled(isEnabled);
  //   } else {
  //     setIsEnabled(false);
  //   }
  //   // })();
  // }, [cache]);

  useEffect(() => {
    (async () => {
      // setFlags(state.flags);
      // let ft = await features.getFeatureFlags();
      // if (ft) {
      //   console.log("FEATURESS", ft.data, flags);
      // }
      console.log("Checking isEnabled", featureName);
      // const isEnabled = await features.isEntitled(featureName, flags);
      const isEnabled = true;
      if (isEnabled) {
        setIsEnabled(isEnabled);
      } else {
        setIsEnabled(false);
      }
    })();
  }, []);

  const features = [
    { Settings: ["Manager", "Administrator", "Super Admin"] },
    { Templates: ["Administrator", "Super Admin"] },
    { Users: ["Manager", "Administrator", "Super Admin"] },
    { "Create Tenant": ["Administrator", "Super Admin"] },
    { "Create User": ["Administrator", "Super Admin"] },
    {
      Competencies: ["Administrator", "Super Admin", "Manager", "Collaborator"],
      description: "Competencies Assessment",
    },
    {
      "Claim Impediment": [
        "Manager",
        "Administrator",
        "Super Admin",
        "Collaborator",
      ],
    },
    {
      "Create Impediment": [
        "Manager",
        "Administrator",
        "Super Admin",
        "Collaborator",
      ],
    },
    {
      "Resolve Impediment": [
        "Manager",
        "Administrator",
        "Super Admin",
        "Collaborator",
      ],
    },
  ];

  const isAllowed = (featureName, role) => {
    const feature = features.find(
      (feature) => Object.keys(feature)[0] === featureName
    );
    console.log("isAllowed", feature);
    if (feature) {
      return feature[featureName].includes(role);
    }
    return false;
  };

  if (type == "nav") {
    return <>{children}</>;
  }
  if (type === "flag") {
    return isEnabled ? <>{children} </> : null;
  }

  if (type === "auth") {
    console.log("isAllowed", role, featureName, isAllowed(featureName, role));
    return isAllowed(featureName, role) === false ? null : <>{children} </>;
  }

  return null;
}
