import io from "socket.io-client";

export const sockets = {
  connect() {
    let socketLink;
    if (
      process.env.REACT_APP_NODE_ENV != "production" &&
      process.env.REACT_APP_NODE_ENV !== "beta"
    ) {
      socketLink = "http://localhost:7001";
    } else {
      socketLink = "https://admin-api.datasecurityu.com";
    }
    var socket = io(socketLink, {
      // path: "/api/featureflags/update",
      transports: ["websocket"],
    });

    socket.on("connect", function () {
      socket.emit("greet", { message: "Hello socket listener" });
    });
    return socket;
  },
  listener() {
    //connect to loca

    var socket = io("http://localhost:7001", {
      // path: "/api/featureflags/update",
      transports: ["websocket"],
    });
    socket.on("connect", function () {
      socket.emit("greet", { message: "Hello socket listener" });
    });

    socket.on("respond", function (data) {
      console.log("I'm very well, thanks");
      socket.emit("greet", { message: "I'm well and you?" });
    });
    socket.on("import-sucess", function () {
      console.log("Import complete, do something");
      //   features.featureFlags();
      console.log("Import complete, did something");
    });

    socket.on("error", (err) => console.log("error", err));
    // socket.on("connect", () => console.log(socket.id)); // ojIckSD2jqNzOqIrAGzL);
    socket.connect();
  },

  closeConnection() {
    console.log("close");
  },
};
