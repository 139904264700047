import React from "react";
import { Link } from "react-router-dom";
import LineChart from "../../charts/LineChart01";
import Icon from "../../images/icon-01.svg";
import EditMenu from "../../components/DropdownEditMenu";
import { NavLink } from "react-router-dom";
// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";

import Skeleton from "../../components/Skeleton";
import RightActionArea from "../../components/ActionAreaRight";

function DashboardCard01(props) {
  const loading = props.loading;
  const title = props.title;
  const burdenSummary = props.burdenSummary;
  const subTitle = props.subTitle ? props.subTitle : null;
  const data = props.data;
  const unfinished = props.unFinishedAssessments;
  const scores = props.scores;
  const effort = props.effort;
  const total = props.total;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const shadow = props.shadow ? props.shadow : true;
  console.info("Props in Activity Card", props);
  const chartData = {
    labels: [
      "12-01-2020",
      "01-01-2021",
      "02-01-2021",
      "03-01-2021",
      "04-01-2021",
      "05-01-2021",
      "06-01-2021",
      "07-01-2021",
      "08-01-2021",
      "09-01-2021",
      "10-01-2021",
      "11-01-2021",
      "12-01-2021",
      "01-01-2022",
      "02-01-2022",
      "03-01-2022",
      "04-01-2022",
      "05-01-2022",
      "06-01-2022",
      "07-01-2022",
      "08-01-2022",
      "09-01-2022",
      "10-01-2022",
      "11-01-2022",
      "12-01-2022",
      "01-01-2023",
    ],
    datasets: [
      // Indigo line
      {
        data: [
          732, 610, 610, 504, 504, 504, 349, 349, 504, 342, 504, 610, 391, 192,
          154, 273, 191, 191, 126, 263, 349, 252, 423, 622, 470, 532,
        ],
        fill: true,
        backgroundColor: `rgba(${hexToRGB(
          tailwindConfig().theme.colors.blue[500]
        )}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.primary[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.primary[500],
        clip: 20,
      },
      // Gray line
      {
        data: [
          532, 532, 532, 404, 404, 314, 314, 314, 314, 314, 234, 314, 234, 234,
          314, 314, 314, 388, 314, 202, 202, 202, 202, 314, 720, 642,
        ],
        borderColor: tailwindConfig().theme.colors.gray[300],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
        clip: 20,
      },
    ],
  };

  return (
    <div
      className={`sm:col-span-full md:col-span-12 md:col-span-12  xl:col-span-${colSpan}  grow-0 row-span-${rowspan} bg-white shadow-lg rounded-sm border border-gray-200`}
    >
      <div className="px-5 pt-5 pb-5">
        <header className="flex grid grid-flow-col align-middle ">
          <div className="inline-flex w-fit align-middle  text-lg font-semibold text-slate-800 mb-2 ml-0">
            {props.title}
            {subTitle && (
              // <RightActionArea>
              <div className="ml-2 inline-flex pt-1 text-xs font-medium  text-slate-500">
                ({subTitle})
              </div>
              // </RightActionArea>
            )}
          </div>

          {/* Menu button */}
          {/* <EditMenu align="right" className="relative inline-flex">
            <li>
              <Link
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                to="#0"
              >
                Option 1
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                to="#0"
              >
                Option 2
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3"
                to="#0"
              >
                Remove
              </Link>
            </li>
          </EditMenu> */}
        </header>
        {loading ? (
          // <Skeleton type="spinner" color="gray" fill="purple" />
          <Skeleton type="spinner" />
        ) : (
          <div className="flex items-start">
            <div className="  text-3xl font-bold text-slate-800 mr-3 mb-2">
              {data}
            </div>

            {unfinished && data != null && (
              <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">
                {unfinished} in progress
              </div>
            )}
            {effort && data != null && (
              <div className=" text-xs font-medium text-white px-1.5 bg-red-700 px-3 rounded-full">
                {effort} days of effort
              </div>
            )}
            {scores && data != null && (
              <div className="inline-flex sm:hidden md:hidden lg:hidden ml-2 text-sm font-semibold text-white px-1.5 bg-red-500 rounded-full">
                {scores.High ? scores.High + " High" : null}
              </div>
            )}
          </div>
        )}
        {burdenSummary && (
          <div className=" items-start justify-start font-medium text-xs px-2 p-3">
            First year potential savings:{" "}
            {burdenSummary["First year savings"] &&
            burdenSummary["First year savings"] > 0
              ? burdenSummary["First year savings"].toFixed(2)
              : 0}{" "}
            days.
            {/* <LineChart data={chartData} width={389} height={128} /> */}
          </div>
        )}
      </div>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
    </div>
  );
}

export default DashboardCard01;
