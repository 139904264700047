import { CheckIcon } from "@heroicons/react/24/outline";
import { useHistory } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingModule({ setShowModal, pricing, tiers }, props) {
  const history = useHistory();
  const handlePricingCTA = (e) => {
    e.preventDefault();
    // history.replace("https://app.breachlink.com");
    window.location.href = "https://app.breachlink.com";
  };
  console.log("Here is my tiers", tiers);
  return (
    <div className="w-screen bg-gray-50 p-10 mx-auto max-w-2xl space-y-12 px-4 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:space-y-0 lg:px-8">
      {tiers.map((tier) => (
        <div
          key={tier.title}
          className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
        >
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-slate-800">
              {tier.title}
            </h3>
            {tier.mostPopular ? (
              <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-emerald-500 py-1.5 px-4 text-sm font-semibold text-white">
                Most popular
              </p>
            ) : null}
            <p className="mt-4 flex items-baseline text-slate-800">
              <span className="text-4xl font-bold tracking-tight">
                {tier.price}
              </span>
              <span className="ml-1 text-xl font-semibold">
                {tier.frequency}
              </span>
            </p>
            <p className="mt-6 text-gray-500">{tier.description}</p>

            {/* Feature list */}
            <ul role="list" className="mt-6 space-y-6">
              {tier.features.map((feature) => (
                <li key={feature} className="flex">
                  <CheckIcon
                    className="h-6 w-6 flex-shrink-0 text-indigo-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3 text-gray-500">{feature}</span>
                </li>
              ))}
            </ul>
          </div>

          <button
            onClick={(e) => {
              handlePricingCTA(e, tier.title);
            }}
            className={classNames(
              tier.mostPopular
                ? "bg-indigo-500 text-white hover:bg-indigo-600"
                : "bg-indigo-50 text-indigo-700 hover:bg-indigo-100",
              "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
            )}
          >
            {tier.cta}
          </button>
        </div>
      ))}
    </div>
  );
}
