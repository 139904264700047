import React, { useState } from "react";
import { useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";

import Table, {
  SelectColumnFilter,
  GlobalFilter,
} from "../components/ReactTable";

import Button from "../elements/Button";
import { FeatureFlag } from "../components/FeatureFlag";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";

import { _competencies } from "../modules/competencies";
import SimpleCard from "../components/SimpleCard";
import RightActionArea from "../components/ActionAreaRight";
import ButtonGroup from "../components/ButtonGroup";
import AssessmentSelect from "./AssessmentSelect";
import ModalBasic from "../components/ModalBasic";
import Skeleton from "../components/Skeleton";
import AssessmentTemplateCreator from "../components/AssessmentTemplatesCreator";
import AssessmentTemplateQuestionEditor from "../components/AssessmentTemplatesQuestEditor";
import { Icon } from "@iconify/react";
import { _auth } from "../modules/_auth";

function Competencies() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [questions, setQuestions] = useState([{}]);
  const [teamCompetencies, setTeamCompetencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [view, setView] = useState("grid");
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [impedimentModalOpen, setImpedimentModalOpen] = useState(false);
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const history = useHistory();
  const stc = true;
  const RenderActions = (props) => {
    // console.log("RenderActions", props.data);
    return (
      <div className="flex items-center space-x-3">
        <Button
          size="sm"
          rounded
          color="secondary"
          loader={loading}
          // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          handleClick={(e) => claim(e, props.row.original, props.data)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-4 h-4 mr-2 -ml-1"
          >
            <path
              fill-rule="evenodd"
              d="M2.232 12.207a.75.75 0 011.06.025l3.958 4.146V6.375a5.375 5.375 0 0110.75 0V9.25a.75.75 0 01-1.5 0V6.375a3.875 3.875 0 00-7.75 0v10.003l3.957-4.146a.75.75 0 011.085 1.036l-5.25 5.5a.75.75 0 01-1.085 0l-5.25-5.5a.75.75 0 01.025-1.06z"
              clip-rule="evenodd"
            />
          </svg>

          <span>Claim</span>
        </Button>
        <Button
          size="sm"
          rounded
          // className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          handleClick={() => viewDetails(props.row.original, props.data)}
        >
          <svg
            className="w-4 h-4 mr-2 -ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 6a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>View</span>
        </Button>
      </div>
    );
  };
  const profile = _auth.getUserProfile();

  const addQuestionRef = useRef(null);

  //get employees from api

  useEffect(() => {
    (async () => {
      let teamCompList = await _competencies.getTeamCompetenciesFromSurvey();
      console.log("TeamCompList", teamCompList);
      if (teamCompList !== undefined) {
        console.log("CompList Team", teamCompList);

        setCompetencies(teamCompList);
        // setList(teamCompList);
        // setLoading(false);
      } else {
        let compList = await _competencies.getCompetencies();
        if (compList) {
          console.log("CompList", compList);
          setCompetencies(compList);
          // setList(compList);
          // setLoading(false);
        }
      }
    })();
  }, [stc]);

  const addImpedimentToCompetency = (impediment) => {
    //update competency state with new question
    console.log("addImpedimentToCompetency", impediment);
    const newCompetency = { ...selectedCompetency };
    newCompetency.impediments.push(impediment);
    // find and remove old competency and add new one
    const newCompetencies = competencies.map((c) => {
      if (c._id === newCompetency._id) {
        return newCompetency;
      }
      return c;
    });
    setCompetencies(newCompetencies);
  };

  const removeQuestionByIndex = (index) => {
    const newQuestions = questions.filter((q, i) => i !== index);
    setQuestions(newQuestions);
    // props.setQuestions(newQuestions);
  };

  const updateQuestionsByIndex = (index, update) => {
    const field = Object.keys(update)[0];
    const value = Object.values(update)[0];
    console.log("Update_question", field, value);

    setQuestions((questions) => {
      const newQuestions = [...questions];
      newQuestions[index][field] = value;
      return newQuestions;
    });
  };

  const replaceQuestion = (question) => {
    console.log("replaceQuestion", question);
    const newQuestions = questions.map((q) => {
      if (q._id == question._id) {
        return question;
      } else {
        return q;
      }
    });
    setQuestions(newQuestions);
  };

  const viewDetails = (row, list) => {
    // console.log("ViewDetails", list);
    let state = { data: row, list: list };
    let path = "/employees/profile";
    return history.push({
      pathname: path,
      state: state,
    });
  };

  const claim = (e, row, list) => {
    e.preventDefault();
    // console.log("ViewDetails", list);
    //set loading for 2 second
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Team",
        accessor: "Team",
        Filter: SelectColumnFilter, // new
        filter: "includes", // new
      },
      {
        Header: "Status",
        accessor: "status",
      },
      //   {
      //     Header: "Role",
      //     accessor: "Role",
      //     Filter: SelectColumnFilter, // new
      //     filter: "includes", // new
      //   },
      //   {
      //     Header: "Manager",
      //     accessor: "Manager",
      //     Filter: SelectColumnFilter, // new
      //     filter: "includes", // new
      //   },
      {
        Header: "Actions",
        accessor: "Actions",
        Cell: RenderActions,
      },
    ],
    []
  );

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const handleClick = (viewType) => {
    console.log("setView", viewType);
    setView(viewType);
  };

  const handleSetImpedimentModalOPen = (data) => {
    console.log("handleSetImpedimentModalOPen", true, data);
    setImpedimentModalOpen(true);
    setSelectedCompetency(data);
  };

  const areas = ["Culture", "Code", "Build", "Test", "Deploy", "Monitor"];

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto space-y-6">
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          <div className="mb-0 sm:mb-0 inline-flex ">
            <h1 className="text-xl md:text-2xl text-slate-800 font-bold">
              Competencies
            </h1>
          </div>
          <RightActionArea>
            {/* <ButtonGroup handleClick={(viewType) => handleClick(viewType)} /> */}

            <Button
              color="secondary"
              shadow
              handleClick={() => setAssessmentModalOpen(true)}
              className="ml-4"
            >
              <DocumentPlusIcon className="h-6 w-6" />
              <span className="hidden xs:block ml-2">Take Assessment</span>
            </Button>
          </RightActionArea>
        </div>
        {/* map areas and create div for each area */}
        {loading ? (
          <Skeleton type="spinner" fill="primary" />
        ) : (
          view !== "table" &&
          areas.map((area) => (
            <div className="bg-white shadow-lg  items-center border border-gray-200  justify-between p-4 space-y-8">
              <h2 className="text-xl font-semibold text-slate-800">{area}</h2>
              {competencies.map(
                (competency) =>
                  competency.Area === area && (
                    <>
                      <div>
                        <SimpleCard
                          setImpedimentModalOpen={() =>
                            handleSetImpedimentModalOPen(competency)
                          }
                          setSelectedCompetency={(comp) =>
                            setSelectedCompetency(comp)
                          }
                          data={competency}
                          view={view}
                        />
                      </div>
                    </>
                  )
              )}
            </div>
          ))
        )}
      </div>
      <ModalBasic
        id="assessment-modal"
        modalOpen={assessmentModalOpen}
        setModalOpen={setAssessmentModalOpen}
        title="Take Assessment"
        showClose={true}
        showFooter
        closeButtonText="Cancel"
      >
        <AssessmentSelect />
        {/* closeModal={setAssessmentModalOpen(false)} */}
      </ModalBasic>

      <ModalBasic
        id="add-impediment-modal"
        modalOpen={impedimentModalOpen}
        setModalOpen={setImpedimentModalOpen}
        title={
          selectedCompetency !== null
            ? "Impediments related to " +
              (selectedCompetency.Area + " - " + selectedCompetency.Name)
            : "Impediments"
        }
        showClose={true}
        showFooter
        closeButtonText="Close"
        scrollable={true}
        size="lg"
      >
        <AssessmentTemplateQuestionEditor
          assessment_type={"impediment"}
          selectedCompetency={selectedCompetency !== null && selectedCompetency}
          questions={
            selectedCompetency !== null && selectedCompetency.impediments
              ? selectedCompetency.impediments
              : []
          }
          addQuestionRef={null} //{addQuestionRef}
          refreshQuestions={addImpedimentToCompetency} //{refreshQuestions}
          setQuestions={() => addImpedimentToCompetency()} //{setQuestions}
          modalItem={null}
          title={"Impediment "}
          description={"Add a new impdiment below"}
        />
      </ModalBasic>
    </>
  );
}

export default Competencies;
