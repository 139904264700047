import React, { useState, useEffect } from "react";
import Customer from "./CustomersTableItem";

import Image01 from "../../images/user-40-01.jpg";
import Image02 from "../../images/user-40-02.jpg";
import Image03 from "../../images/user-40-03.jpg";
import Image04 from "../../images/user-40-04.jpg";
import Image05 from "../../images/user-40-05.jpg";
import Image06 from "../../images/user-40-06.jpg";
import Image07 from "../../images/user-40-07.jpg";
import Image08 from "../../images/user-40-08.jpg";
import Image09 from "../../images/user-40-09.jpg";
import Image10 from "../../images/user-40-10.jpg";
//Api
import { customers } from "../../modules/_customers";
import { _assessment } from "../../modules/_assessment";
import Skeleton from "../../components/Skeleton";
import { useStore } from "../../store/usestore";

function CustomersTable({ selectedItems, data, fromDashboard, ...props }) {
  const loading = "loading";
  const [history, setHistory] = useState("loading");
  const { userAssessmentHistory, getUserAssessmentHistory } = useStore();
  const [deletedArr, setDeletedArr] = useState([]);

  useEffect(() => {
    if (fromDashboard == true) {
      console.log("From Dashboard, getting history from props", data);
      setHistory(data);
    } else {
      (async () => {
        let assessment_history = await _assessment.getAssessmentHistory();
        setHistory(assessment_history);
      })();
    }
  }, [data]);

  console.info("CustomersTable", data);
  // const custlist = data;
  // const goToActionPlan = props.goToActionPlan;
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const setModalItem = (d) => {
    console.info("ATTENTION - SHOW HIDE MODAL CLICKED");
    console.info("ATTENTION D", d);
    props.setModalItem(d);
  };
  useEffect(() => {
    if (data.length > 0) {
      setList(data);
    } else {
      setList(history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //update after delete
  useEffect(() => {
    (async () => {
      let assessment_history = await _assessment.getAssessmentHistory();
      setHistory(assessment_history);
    })();
  }, [deletedArr]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const deleteAssessment = async (id) => {
    console.log("deleteAssessment", id);
    const deleted = await _assessment.deleteAssessment(id);
    if (deleted) {
      console.log("deleted", deleted);
      //delete from list by id and update state
      setDeletedArr([...deletedArr, id]);
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <div style={{ maxHeight: "65vh", minHeight: "65vh" }}>
      <div
        style={{ maxHeight: "65vh", minHeight: "65vh" }}
        className="overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full "
      >
        <table className="table-fixed w-full">
          <thead className="sticky z-10 top-0 text-xs font-semibold uppercase text-slate-500 bg-gray-50 border-t border-b border-gray-200">
            <tr>
              {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="flex items-center">
                  <label className="inline-flex">
                    <span className="sr-only">Select all</span>
                    <input
                      className="form-checkbox"
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </label>
                </div>
              </th> */}
              {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <span className="sr-only">Favourite</span>
                </th> */}
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-fit w-1/12">
                <div className="font-semibold text-left">ID</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">Taken by</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-1/12">
                <div className="font-semibold text-left">Type</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-fit w-3/12 ">
                <div className="font-semibold text-left">Taken on</div>
              </th>
              {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap max-w-48 ">
                <div className="font-semibold text-left">Risk</div>
              </th> */}

              {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Total spent</div>
                </th> */}
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  max-w-48 ">
                <div className="font-semibold text-left">Status</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-2/12 ">
                <div className="font-semibold text-left">Actions</div>
              </th>
              {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <span className="sr-only">Menu</span>
              </th> */}
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-gray-200 h-20 overflow-scroll">
            {/* <tbody
              class="bg-grey-light flex flex-col items-center justify-between overflow-y-scroll w-full"
              style={{ height: "50vh" }}
            > */}
            {/* {data == "loading" ? "LOADING" : "TEST"} */}

            {history == "loading" ? (
              <div
                className={`w-screen ${
                  fromDashboard == false && "grid min-w-full h-full m-auto"
                }`}
              >
                <div className="m-auto">
                  <Skeleton type="spinner" color="gray" fill="purple" />
                </div>
              </div>
            ) : (
              history
                .slice(0)
                .reverse()
                .map((customer) => {
                  console.info("DB11 Assessment History Table DATA", customer);

                  const assessment_id = customer.assessment_id;
                  console.info("ASSESMENT", assessment_id);
                  const assessment_status = customer.assessment_status;
                  const assessment_type = customer.assessment_type;
                  const assessment_score = customer.risk_score;
                  const assessment_taken_by = customer.taken_by;
                  const contributorArray = [];
                  customer.length > 0 &&
                    customer.questions.map((item) => {
                      item.answered_by &&
                        item.answered_by != null &&
                        item.risk_score != "null" &&
                        contributorArray.push(item.answered_by);
                    });
                  console.info("Cont Arry", contributorArray);
                  const countOfEach = {};
                  for (const num of contributorArray) {
                    countOfEach[num] = countOfEach[num]
                      ? countOfEach[num] + 1
                      : 1;
                  }
                  // console.info(" Cont Arry CountofEach", countOfEach);
                  // console.info(
                  //   "COUNTOFECH > 0",
                  //   Object.keys(countOfEach).length > 0
                  // );
                  const contributors = Object.keys(countOfEach).length > 1;
                  // const [avgRisk, setAvgRisk] = useState(
                  //   Object.keys(countOfEach).length > 0
                  //     ? Object.keys(countOfEach).reduce((a, b) =>
                  //         countOfEach[a] > countOfEach[b] ? a : b
                  //       )
                  //     : null
                  // );
                  // setAvgRisk(

                  // );
                  // console.info(" Cont Arry test", avgRisk);

                  const res = customer.questions
                    ? customer.questions.filter((item) => "your_answer" in item)
                        .length
                    : null;
                  const progress =
                    res != null ? res / customer.questions.length : null;
                  // console.info(
                  //   "PROGRESS",
                  //   res,
                  //   "answered out of",

                  //   // customer.questions.length,
                  //   progress
                  // );

                  // const assessment_taken_on = customer.assessment_date;
                  const assessment_taken_on = new Date(
                    customer.assessment_date
                  ).toLocaleDateString("en-us", {
                    // weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                  const assessment_taken_by_email = customer.taken_by_email;
                  console.info("LAST ORDER", customer.status);
                  return (
                    <Customer
                      key={assessment_id}
                      id={assessment_id}
                      image={customer.image}
                      name={assessment_taken_by}
                      email={assessment_taken_by_email}
                      type={
                        assessment_type !== undefined
                          ? assessment_type
                          : customer.assessment_type
                      }
                      orders={customer.orders}
                      date={assessment_taken_on}
                      spent={customer.spent}
                      risk={assessment_score}
                      status={assessment_status}
                      fav={customer.fav}
                      handleClick={handleClick}
                      setModalItem={setModalItem}
                      handleDeleteAssessment={deleteAssessment}
                      isChecked={isCheck.includes(customer.id)}
                      // goToActionPlan={goToActionPlan}
                      counts={customer.counts}
                      thisAssessment={customer}
                      progress={progress}
                      contributors={contributors}
                      fromDashboard={fromDashboard}
                    />
                  );
                })
            )}
            {/* : history.length > 0 &&
                history
                  .slice(0)
                  .reverse()
                  .map((customer) => {
                    console.info(
                      "DB11 Assessment history table HISTORY",
                      customer
                    );

                    const assessment_id = customer.assessment_id;
                    console.info("ASSESMENT", assessment_id);
                    const assessment_status = customer.assessment_status;
                    const assessment_type = customer.assessment_type;
                    const assessment_score = customer.risk_score;
                    const assessment_taken_by = customer.taken_by;
                    const contributorArray = [];
                    customer.questions.map((item) => {
                      item.answered_by &&
                        item.answered_by != null &&
                        item.risk_score != "null" &&
                        contributorArray.push(item.answered_by);
                    });
                    console.info("Cont Arry", assessment_id, contributorArray);
                    const countOfEach = {};
                    for (const num of contributorArray) {
                      countOfEach[num] = countOfEach[num]
                        ? countOfEach[num] + 1
                        : 1;
                    }
                    console.info(
                      " Cont Arry CountofEach",
                      assessment_id,
                      countOfEach
                    );
                    console.info(
                      "COUNTOFECH > 0",
                      assessment_id,
                      Object.keys(countOfEach).length > 0
                    );
                    const contributors = Object.keys(countOfEach).length > 1;
                    // const [avgRisk, setAvgRisk] = useState(
                    //   Object.keys(countOfEach).length > 0
                    //     ? Object.keys(countOfEach).reduce((a, b) =>
                    //         countOfEach[a] > countOfEach[b] ? a : b
                    //       )
                    //     : null
                    // );
                    // setAvgRisk(

                    // );
                    // console.info(" Cont Arry test", avgRisk);

                    const res = customer.questions
                      ? customer.questions.filter(
                          (item) => "your_answer" in item
                        ).length
                      : null;
                    const progress =
                      res != null ? res / customer.questions.length : null;
                    console.info(
                      "PROGRESS",
                      res,
                      "answered out of",

                      // customer.questions.length,
                      progress
                    );

                    // const assessment_taken_on = customer.assessment_date;
                    const assessment_taken_on = new Date(
                      customer.assessment_date
                    ).toLocaleDateString("en-us", {
                      // weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    });
                    const assessment_taken_by_email = customer.taken_by_email;
                    console.info("LAST ORDER", customer.status);
                    return (
                      <Customer
                        key={assessment_id}
                        id={assessment_id}
                        image={customer.image}
                        name={assessment_taken_by}
                        email={assessment_taken_by_email}
                        type={assessment_type}
                        orders={customer.orders}
                        date={assessment_taken_on}
                        spent={customer.spent}
                        risk={assessment_score}
                        status={assessment_status}
                        fav={customer.fav}
                        handleClick={handleClick}
                        setModalItem={setModalItem}
                        isChecked={isCheck.includes(customer.id)}
                        // goToActionPlan={goToActionPlan}
                        counts={customer.counts}
                        thisAssessment={customer}
                        progress={progress}
                        contributors={contributors}
                        fromDashboard={fromDashboard}
                      />
                    );
                  })} */}
          </tbody>
        </table>
      </div>
    </div>
    // </div>
    //{" "}
  );
}

export default CustomersTable;
