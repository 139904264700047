export const assessments = {
  //   getCompletedPlaybooks() {
  //     let newArr = [];
  //     // let res = require("./completed_playbooks.json");
  //     let json = require("./completed_playbooks.json");
  //     const res = JSON.parse(JSON.stringify(json));

  //     console.info("PB CompletedPlaybooks", res);
  //     res["Form1"].map((item) => {
  //       console.info("PB CompletedPlaybooks ITEM", item);
  //       const key = item["ID"];
  //       const id = item["ID"];
  //       const team = item["Please enter your team name below:"];
  //       const playbook =
  //         item["Please select the Playbook you have just completed"];
  //       const date = new Date(item["Completion time"]);
  //       const shortDate = new Date(date).toLocaleDateString("en-us", {
  //         year: "numeric",
  //         month: "long",
  //         day: "numeric",
  //       });

  //       var newObj = {};
  //       newObj["key"] = key;
  //       newObj["id"] = id;
  //       newObj["team"] = team;
  //       newObj["playbook"] = playbook;
  //       newObj["date"] = shortDate;
  //       console.info("NEWOBJ", newObj);
  //       newArr.push(newObj);
  //     });
  //     console.info("NEWOBJ ARR", newArr);
  //     return newArr;
  //   },

  getTotalTeamsCompletedPlaybooks(key, data) {
    let completed_playbooks = [];
    if (data) {
      completed_playbooks = data;
    } else {
      completed_playbooks = null;
    }

    console.info("PB completed_playbooks", key, completed_playbooks);
    let teams_completed = [];

    completed_playbooks.map((item) => {
      console.info("PB", key, " --> ITEM", item);
      if (item[key] != undefined) {
        teams_completed.push(item[key]);
      }
    });
    console.info("PB teams_completed", teams_completed);
    return teams_completed;
  },

  getDateTotals(data) {
    console.info("getDateTotals", data);
    let c = this.getTotalTeamsCompletedPlaybooks("assessment_date", data);
    console.info("getDateTotals", c);

    let countOfEach = {};
    for (const num of c) {
      countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
    }
    console.info("getDateTotals countOfEach", countOfEach);

    return countOfEach;
  },

  getTotals(data, key, return_sorted = false) {
    console.info("getTotals", data, key);
    let c = [];
    data.map((item) => {
     // console.info("PB", key, " --> ITEM", item);
      if (item[key] != undefined) {
        c.push(item[key]);
      }
    });

    let countOfEach = {};
    for (const num of c) {
      countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
    }

    const sorted = Object.entries(countOfEach)
      .sort(([, a], [, b]) => a - b)
      .reverse();
    console.info("getTotals", data, key, c, countOfEach, sorted);
    if (return_sorted == true) {
      return sorted;
    } else {
      return countOfEach;
    }
  },

  getCountOfEach(counts) {
    var countOfEach = {};
    for (const num of counts) {
      countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
    }
    const sorted = Object.entries(countOfEach)
      .sort(([, a], [, b]) => a - b)
      .reverse();
    return sorted;
  },
};
