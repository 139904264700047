import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const _user = {
  getUserPanelTabs() {
    const userPanelTabs = [
      { name: "All Users", href: "#", current: true },
      { name: "Invite Collaborators", href: "#", current: false },
      { name: "Create Teams", href: "#", current: false },
    ];
    return userPanelTabs;
  },
  async bulkUserUpdate(csv, type) {
    let profile = _auth.getUserProfile();
    try {
      console.log(csv, "csv");
      const data = new FormData();

      data.append("file", csv);
      data.append("companyId", profile.companyId);
      data.append("company", profile.company);
      data.append("tenantId", profile.tenantId);
      data.append("type", type);
      let response = await postRequest("create-bulk-user", data);
      if (response.data) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async addSingleUser(data) {
    //conditionally call either register or invite user
    //depending on data.createTenant value (true or false)

    console.info("COLLAB Add single user with tenant id", data);
    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response;
      if (data.createTenant) {
        response = await postRequest("register", data);
      } else {
        response = await postRequest("new-single-user", data);
      }
      if (response.status === 200) {
        console.info("Response Returned", response);
        return response;
      } else {
        console.info("Response Returned", response);
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async createOnBoard(data) {
    try {
      let response = await postRequest("/on-board");
      if (response.data) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getAllUser(page) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await getRequest(`get-all-users`, {
        params: {
          page: page,
          tenantId: _auth.getUserProfile().tenantId,
        },
      });
      ////debugger;
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async getTenantUsers(page) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await getRequest(`get-tenant-users`, {
        params: {
          page: page,
          tenantId: _auth.getUserProfile().tenantId,
        },
      });
      ////debugger;
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async disableUser(id) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("disable-user", {
        user_id: id,
      });
      // //debugger;
      if (response.data) {
        const allusers = this.getAllUser();
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async enableUser(id) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("enable-user", {
        user_id: id,
      });
      // //debugger;
      if (response.data) {
        const allusers = this.getAllUser();
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  canBeReinvited(dt) {
    let date = new Date(dt);
    let today = new Date();
    let diff = Math.abs(today - date);
    let diffHours = Math.ceil(diff / (1000 * 60 * 60));
    console.info("diff", diffHours);
    if (diffHours > 24) {
      return true;
    } else {
      return false;
    }
  },

  async reinviteUser(id) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("resend-invite", {
        user_id: id,
      });
      ////debugger;
      if (response) {
        return true;
      } else {
        return response;
      }
      // if (response.data) {
      //   return;
      // } else {
      //   return [];
      // }
    } catch (e) {
      return e;
    }
  },
  async deleteUserFromTenant(data) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("delete-user-tenant", {
        user_id: data.id,
        tenantId: data.tenantId,
      });
      // //debugger;
      if (response.data) {
        const allusers = this.getAllUser();
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async updateUserStatus(data, status) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("update-user-status", {
        user_id: data.id,
        tenantId: data.tenantId,
        status: status,
      });
      // //debugger;
      if (response.data) {
        const allusers = this.getAllUser();
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async renameTenant(data, key) {
    let profile = _auth.getUserProfile();
    console.log("ONBOARD rename tenant api", profile.tenantId, data, key);

    let body = {
      tenantName: data,
      tenantId: profile.tenantId,
      key: key,
    };
    // try {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("rename-tenant", body);
    if (response.data) {
      return response.data;
    } else {
      return false;
    }
    // } catch (e) {
    //   return e;
    // }
  },
  async createTenant(data) {
    let profile = _auth.getUserProfile();
    console.log("create tenant", profile);

    let body = {
      tenantName: data["Team Name"],
      tenantCompany: profile.company,
      companyName: profile.company,
      companyId: profile.companyId,
      role: "Manager",
      tenantCompanyID: profile.companyId,
      createdBy: profile.name,
      createdById: profile.user_id,
      key: data.key,
      teamId: data._id,
    };
    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("create-tenant", body);
      if (response.data.data) {
        return response.data.data.data;
      } else {
        return false;
      }
    } catch (e) {
      return e;
    }
  },
};
