// import _authService from "../modules/authService";
// import { SESSION_TOKEN } from "../constants/constants";
import { _assessment } from "../modules/_assessment";
import { qList } from "../modules/_assessment_questions";

const createAssesmentSlice = (set) => ({
  status: "",
  isSuccess: false,
  questions: null,
  loading: false,
  userAssessmentHistory: [],

  setLoading: async () => {
    set(() => ({
      loading: false,
    }));
  },
  sendAssessment: async (emails, type) => {
    let response = await _assessment.sendAssessment(emails, type);
    if (response) {
      // set(() => {})
      console.log(response);
    } else {
      console.log(response);
    }
  },
  getQuestions: async (type) => {
    let response = await qList.getQuestionWithType(type);
    if (response.error.length > 0) {
    } else {
    }
  },
  getUserAssessmentHistory: async (guestProfile) => {
    set(() => ({
      loading: true,
    }));
    debugger
    if(guestProfile) {
      let response = await _assessment.getAssessmentHistory(guestProfile);
      console.log("RESPONSESLICE", response);
      if (response) {
      
        let assessmentHistoryArray = [];
        assessmentHistoryArray.push(response);
        console.log(assessmentHistoryArray, "responsible");
        set(() => ({
          isSuccess: true,
          userAssessmentHistory: [...assessmentHistoryArray],
        }));
      }
    } else {
      debugger
      let response = await _assessment.getAssessmentHistory();
    console.log("RESPONSESLICE", response);
    debugger
    if (response) {
      debugger
      let assessmentHistoryArray = [];
      assessmentHistoryArray.push(response);
      console.log(assessmentHistoryArray, "responsible");
      set(() => ({
        isSuccess: true,
        userAssessmentHistory: [...assessmentHistoryArray],
      }));
    }
    }
    
  },
});

export default createAssesmentSlice;
