import { utils } from "./_utils";
import { postRequest, getRequest } from "./ApiRequest";

export const contactus = {
  async contact_email(params) {
    console.info("Received contact email params", params);
    try {
      const response = await postRequest("email", params);
      if (response && response.data && response.data.status === 200) {
        // history.push("/home")
        return response;
      } else {
        console.log("not running");
      }
    } catch (e) {
      console.log(e);
    }
  },
  async marketplace_email(params) {
    console.info("Received marketplace params", params);
    try {
      const response = await postRequest("email", params);
      if (response && response.data && response.data.status === 200) {
        // history.push("/home")
        return response;
      } else {
        console.log("not running");
      }
    } catch (e) {
      console.log(e);
    }
  },
};
